import classNames from 'classnames';
import React, { ReactHTMLElement, ReactNode, useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { OptionProps, OptionTypeBase } from 'react-select/src/types';

import { ClipboardListIcon } from '@/components/icons';
import { InlineIconButton } from '@/components/InlineIconButton';
import { BaseInputFieldProps } from '../BaseInputField';
import { BaseWrapper } from '../BaseWrapper';
import BulkImportField, { ItemProps } from './BulkImportField';
import './react-select.css';
import SelectedList from './SelectedList';

interface SearchAndSelectProps extends BaseInputFieldProps {
  placeholder?: string;
  isMultiple?: boolean;
  loadOptions: LoadOptions;
  component: ReactNode;
  config?: ConfigProps;
}

type LoadOptions =
  | ((
      inputValue: string,
      callback: (options: readonly object[]) => void,
    ) => void | Promise<readonly object[]>)
  | undefined;

type ConfigProps = SharedConfigProps & (ConfigBulkProps | ConfigNonBulkProps);

type SharedConfigProps = {
  defaultAttrName: string;
  option: (item: OptionProps) => ItemProps;
};

type ConfigNonBulkProps = {
  enableBulk?: false | undefined;
};

type ConfigBulkProps = {
  listItem: (item: ItemProps) => ItemProps;
  enableBulk: true;
  onImportBulk: OnImportBulkProps;
};

export type OnImportBulkProps = {
  (items: string[] | undefined): {
    results: any[] | undefined;
    loading: boolean;
  };
};

export const SearchAndSelectField = (props: SearchAndSelectProps) => {
  const { loadOptions, component, isMultiple, config, name } = props;
  const { setValue, control } = useFormContext();
  const [options, setOptions] = useState<ItemProps[]>([]);
  const [option, setOption] = useState<ItemProps | never[]>();
  const [showBulk, setShowBulk] = useState<boolean>(false);

  useEffect(() => {
    const values = options.map(({ itemId }) => itemId);
    setValue(name, values);
  }, [name, options, setValue]);

  useEffect(() => {
    setValue(name, option);
  }, [name, option, setValue]);

  const handleRemove = (itemId: string) => {
    const filteredItems = options.filter((option) => option.itemId !== itemId);
    setOptions(filteredItems);
  };

  const handleRemoveAll = () => {
    setOptions([]);
  };

  const onSelect = useCallback(
    (value) => {
      const newValue = config?.option(value) || [];
      if (isMultiple) {
        setOptions(options.concat(newValue));
      } else {
        setOption(newValue);
      }
    },
    [config, isMultiple, options, setOptions],
  );

  const callbackList = (items: ItemProps[] | undefined) => {
    setOptions(options.concat(items || []));
  };

  const Option = ({ innerProps, ...props }: OptionTypeBase) => {
    const childComp = React.cloneElement(
      component as ReactHTMLElement<HTMLElement>,
      {
        ...props,
      },
    );

    return <div {...innerProps}>{childComp}</div>;
  };

  const BulkButton = () => {
    return (
      <InlineIconButton
        onClick={() => setShowBulk(!showBulk)}
        className={classNames(
          `mt-2 p-2 ml-1 border border-gray-400 rounded-md`,
          {
            'bg-gray-100': showBulk,
          },
        )}
        type="button"
      >
        <ClipboardListIcon />
      </InlineIconButton>
    );
  };

  const getValue = (field: ControllerRenderProps<FieldValues, string>) => {
    return isMultiple
      ? null
      : options.find(({ value }) => value === field.value);
  };

  return (
    <div className="flex flex-col md:flex-row md:space-x-4">
      <div className="w-full">
        <BaseWrapper title={props.title} name={props.name}>
          <Controller
            name={props.name}
            control={control}
            rules={{ required: props.required }}
            render={({ field }) => (
              <div className="flex w-full">
                <AsyncSelect
                  className="w-full mt-1 rounded-md"
                  classNamePrefix="react-asyncselect-override"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor: '',
                      borderRadius: '',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      marginLeft: '',
                      color: '',
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: '',
                    }),
                  }}
                  value={getValue(field)}
                  cacheOptions
                  components={{
                    Option,
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={props.placeholder || 'Search and select...'}
                  loadOptions={loadOptions}
                  onChange={onSelect}
                />
                {config?.enableBulk && <BulkButton />}
              </div>
            )}
          />
          {config?.enableBulk && showBulk && (
            <BulkImportField
              list={callbackList}
              listItem={config.listItem}
              onImportBulk={config.onImportBulk}
              defaultAttrName={config.defaultAttrName}
            />
          )}
          {isMultiple && (
            <SelectedList
              options={options}
              removeItem={handleRemove}
              removeAll={handleRemoveAll}
            />
          )}
        </BaseWrapper>
      </div>
    </div>
  );
};
