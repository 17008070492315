import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  isBefore,
  subMonths,
} from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

import { TranslateFn, useTranslate } from '@/contexts';
import { Nullable } from '@/types';
import { assert } from '@/utils/error';
import {
  UseTimeAgoBitStaticQuery,
  UseTimeAgoBitStaticQuery_sanityTimeAgoBit,
} from './__generated__/UseTimeAgoBitStaticQuery';

const query = graphql`
  query UseTimeAgoBitStaticQuery {
    sanityTimeAgoBit {
      about {
        ...LocaleString
      }
      yearUnit {
        ...LocaleString
      }
      yearsUnit {
        ...LocaleString
      }
      monthUnit {
        ...LocaleString
      }
      monthsUnit {
        ...LocaleString
      }
      dayUnit {
        ...LocaleString
      }
      daysUnit {
        ...LocaleString
      }
      hourUnit {
        ...LocaleString
      }
      hoursUnit {
        ...LocaleString
      }
      minuteUnit {
        ...LocaleString
      }
      minutesUnit {
        ...LocaleString
      }
      justNow {
        ...LocaleString
      }
      ago {
        ...LocaleString
      }
    }
  }
`;

type GetTimeAgo = (
  date: Nullable<string | number | Date>,
) => string | undefined;

const shouldRoundUpToNextHour = (minutesDiff: number) => minutesDiff % 60 >= 30;
const shouldRoundUpToNextDay = (hoursDiff: number) => hoursDiff % 24 >= 18;

const shouldRoundUpToNextMonth = (
  monthsDiff: number,
  now: Date,
  targetDate: Date,
) => {
  const sameMonthDate = subMonths(now, monthsDiff);
  return differenceInDays(sameMonthDate, targetDate) > 14;
};

export const createGetTimeAgo = ({
  t,
  timeAgoBit,
}: {
  t: TranslateFn;
  timeAgoBit: UseTimeAgoBitStaticQuery_sanityTimeAgoBit;
}): GetTimeAgo => {
  const getTimeAgo: GetTimeAgo = (dateValue) => {
    if (!dateValue) {
      return '-';
    }

    const date = new Date(dateValue);
    const now = new Date();

    const minutesDiff = differenceInMinutes(now, date);
    const hoursDiff = differenceInHours(now, date);
    const daysDiff = differenceInDays(now, date);
    const monthsDiff = differenceInMonths(now, date);
    const yearsDiff = differenceInYears(now, date);

    switch (true) {
      case isBefore(now, date): {
        if (process.env.NODE_ENV !== 'test') {
          console.warn('The target date is in the future');
        }
        return undefined;
      }

      case minutesDiff < 1:
        return t(timeAgoBit.justNow);

      case minutesDiff < 2:
        return `1 ${t(timeAgoBit.minuteUnit)} ${t(timeAgoBit.ago)}`;

      case minutesDiff < 50: {
        return `${minutesDiff} ${t(timeAgoBit.minutesUnit)} ${t(
          timeAgoBit.ago,
        )}`;
      }

      case minutesDiff < 90:
        return `${t(timeAgoBit.about)} 1 ${t(timeAgoBit.hourUnit)} ${t(
          timeAgoBit.ago,
        )}`;

      case hoursDiff < 24:
        return `${t(timeAgoBit.about)} ${
          shouldRoundUpToNextHour(minutesDiff) ? hoursDiff + 1 : hoursDiff
        } ${t(timeAgoBit.hoursUnit)} ${t(timeAgoBit.ago)}`;

      case hoursDiff < 42:
        return `${t(timeAgoBit.about)} 1 ${t(timeAgoBit.dayUnit)} ${t(
          timeAgoBit.ago,
        )}`;

      case daysDiff < 30:
        return `${t(timeAgoBit.about)} ${
          shouldRoundUpToNextDay(hoursDiff) ? daysDiff + 1 : daysDiff
        } ${t(timeAgoBit.daysUnit)} ${t(timeAgoBit.ago)}`;

      case daysDiff < 45 ||
        (monthsDiff === 1 && !shouldRoundUpToNextMonth(monthsDiff, now, date)):
        return `${t(timeAgoBit.about)} 1 ${t(timeAgoBit.monthUnit)} ${t(
          timeAgoBit.ago,
        )}`;

      case yearsDiff < 1: {
        const roundUpMonth = shouldRoundUpToNextMonth(monthsDiff, now, date);
        return `${t(timeAgoBit.about)} ${
          roundUpMonth ? monthsDiff + 1 : monthsDiff
        } ${t(timeAgoBit.monthsUnit)} ${t(timeAgoBit.ago)}`;
      }

      case yearsDiff < 2:
        return `${t(timeAgoBit.about)} 1 ${t(timeAgoBit.yearUnit)} ${t(
          timeAgoBit.ago,
        )}`;

      default:
        return `${yearsDiff}+ ${t(timeAgoBit.yearsUnit)} ${t(timeAgoBit.ago)}`;
    }
  };

  return getTimeAgo;
};

export const useTimeAgo = (): GetTimeAgo => {
  const staticData = useStaticQuery<UseTimeAgoBitStaticQuery>(query);
  const { t } = useTranslate();

  const timeAgoBit = staticData.sanityTimeAgoBit;
  assert(timeAgoBit, 'missing feedback messages');

  const getTimeAgo = useMemo(
    () =>
      createGetTimeAgo({
        t,
        timeAgoBit,
      }),
    [t, timeAgoBit],
  );

  return getTimeAgo;
};
