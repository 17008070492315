import {
  BooleanParam,
  DateTimeParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { PlayerKycHistorySearchOrder } from '@/globalTypes';
import {
  KycProviderTypeParam,
  PlayerKycHistorySearchOrderParam,
} from '@/utils/query/params';

export const queryParams = {
  kycProvider: KycProviderTypeParam,
  getOnlyLatestKycPerPlayer: BooleanParam,
  from: DateTimeParam,
  to: DateTimeParam,
  desc: withDefault(BooleanParam, true),
  orderBy: withDefault(
    PlayerKycHistorySearchOrderParam,
    PlayerKycHistorySearchOrder.processedAt,
  ),
  before: StringParam,
  after: StringParam,
};
