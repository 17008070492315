import React, { ComponentType, useCallback, useMemo } from 'react';

import { useViewer } from '@/contexts';
import { AgentRoleEnum } from '@/globalTypes';

type RoleRecord = Partial<Record<AgentRoleEnum, true>>;

const useRoleRecord = () => {
  const { viewer } = useViewer();

  return useMemo(() => {
    return (viewer?.identity.roles ?? []).reduce<RoleRecord>((acc, role) => {
      acc[role] = true;
      return acc;
    }, {});
  }, [viewer?.identity.roles]);
};

const getHasRole = (roleRecord: RoleRecord) => (role: AgentRoleEnum) =>
  role && !!roleRecord[role];

export const useGetHasAnyOfRoles = () => {
  const roleRecord = useRoleRecord();

  const hasAnyOfRoles = useCallback(
    (roles: AgentRoleEnum[]) => {
      const hasRole = getHasRole(roleRecord);
      return roles.some(hasRole);
    },
    [roleRecord],
  );

  return hasAnyOfRoles;
};

const createAccessConfig = <K extends string>(
  entries: Record<K, AgentRoleEnum[]>,
) => entries;

const accessConfig = createAccessConfig({
  MANAGE_AFFILIATES: [AgentRoleEnum.AffiliateManager],
  DEFAULT_NOTE_HIGH_PRIORITY: [
    AgentRoleEnum.Manager,
    AgentRoleEnum.PaymentAgent,
    AgentRoleEnum.PaymentTL,
    AgentRoleEnum.PaymentsAndFraud,
  ],
  APPROVE_PENDING_ZIMPLER_WITHDRAWAL: [
    AgentRoleEnum.Manager,
    AgentRoleEnum.PaymentAgent,
    AgentRoleEnum.PaymentTL,
  ],
});

export const useCan = (action: keyof typeof accessConfig) => {
  const hasAnyOfRoles = useGetHasAnyOfRoles();
  const actionConfig = accessConfig[action];

  if (!actionConfig) {
    console.error(`No action config for action ${action}`);
    return false;
  }

  return hasAnyOfRoles(actionConfig);
};

export function withCanGuard(action: keyof typeof accessConfig) {
  return function getComponentWithCanGuard<T>(Component: ComponentType<T>) {
    const ComponentWithCanGuard = (props: T) => {
      const can = useCan(action);

      if (can) {
        return <Component {...props} />;
      }

      return null;
    };

    return ComponentWithCanGuard;
  };
}
