import {
  BooleanParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { BrandParam } from '@/utils/query/params';

export const queryParams = {
  after: StringParam,
  before: StringParam,
  brand: BrandParam,
  desc: withDefault(BooleanParam, true),
  first: NumberParam,
  last: NumberParam,
};
