/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Statuses of a player accounts
 */
export enum AccountStatusEnum {
  Closed = "Closed",
  Open = "Open",
}

/**
 * Adjustment Category.
 */
export enum AdjustmentCategory {
  Adjustment = "Adjustment",
  Bonus = "Bonus",
}

/**
 * Adjustment Type
 */
export enum AdjustmentType {
  Bonus = "Bonus",
  Cashdrop = "Cashdrop",
  Gameplay = "Gameplay",
  Goodwill = "Goodwill",
  Payment = "Payment",
  TestFunds = "TestFunds",
}

/**
 * Agent role type
 */
export enum AgentRoleEnum {
  AccessAmok = "AccessAmok",
  AccessMountGold = "AccessMountGold",
  AccessRaptor = "AccessRaptor",
  AccessRefuelCasino = "AccessRefuelCasino",
  Adjustments = "Adjustments",
  AffiliateManager = "AffiliateManager",
  CMS = "CMS",
  CustomerSupport = "CustomerSupport",
  CustomerSupportAgent = "CustomerSupportAgent",
  CustomerSupportTL = "CustomerSupportTL",
  Developers = "Developers",
  Manager = "Manager",
  Marketing = "Marketing",
  PaymentAgent = "PaymentAgent",
  PaymentTL = "PaymentTL",
  PaymentsAndFraud = "PaymentsAndFraud",
  VIPManager = "VIPManager",
}

/**
 * The aml risk level of a player
 */
export enum AmlRiskLevel {
  High = "High",
  Low = "Low",
  Medium = "Medium",
  NotSet = "NotSet",
}

/**
 * The brand of the site.
 */
export enum BrandEnum {
  AMOK = "AMOK",
  MRGLD = "MRGLD",
  MTGLD = "MTGLD",
  RAPTR = "RAPTR",
  RFLCN = "RFLCN",
}

/**
 * Reward claim type
 */
export enum ClaimType {
  Instant = "Instant",
  Manual = "Manual",
}

/**
 * Cause of closing account.
 */
export enum CloseAccountCause {
  AbusiveBehaviour = "AbusiveBehaviour",
  BlockCustomerRequest = "BlockCustomerRequest",
  CloseCustomerRequest = "CloseCustomerRequest",
  ConfirmedFraud = "ConfirmedFraud",
  ConfirmedPromotionAbuse = "ConfirmedPromotionAbuse",
  MigrationCause = "MigrationCause",
  MultipleAccounts = "MultipleAccounts",
  Other = "Other",
  PEPSanctionsListMatch = "PEPSanctionsListMatch",
  ResponsibleGaming = "ResponsibleGaming",
  SanctionsListMatch = "SanctionsListMatch",
  SuspectedFraud = "SuspectedFraud",
  SuspectedPromotionAbuse = "SuspectedPromotionAbuse",
}

/**
 * Cause of closing account.
 */
export enum CloseAccountCauseV2 {
  AbusiveBehaviour = "AbusiveBehaviour",
  ConfirmedFraud = "ConfirmedFraud",
  ConfirmedPromotionAbuse = "ConfirmedPromotionAbuse",
  MultipleAccounts = "MultipleAccounts",
  Other = "Other",
  PEPSanctionsListMatch = "PEPSanctionsListMatch",
  ResponsibleGaming = "ResponsibleGaming",
  SanctionsListMatch = "SanctionsListMatch",
  SuspectedFraud = "SuspectedFraud",
  SuspectedPromotionAbuse = "SuspectedPromotionAbuse",
}

/**
 * The status of a document.
 */
export enum DocumentStatus {
  Approved = "Approved",
  Deleted = "Deleted",
  Pending = "Pending",
  Rejected = "Rejected",
}

/**
 * Type of document
 */
export enum DocumentType {
  BankStatement = "BankStatement",
  CreditCard = "CreditCard",
  DividendsSOW = "DividendsSOW",
  DrivingLicense = "DrivingLicense",
  ID = "ID",
  InheritanceSOW = "InheritanceSOW",
  Other = "Other",
  Passport = "Passport",
  SalarySOW = "SalarySOW",
  SaleOfCompanySOW = "SaleOfCompanySOW",
  SaleOfPropertySOW = "SaleOfPropertySOW",
  SourceOfWealth = "SourceOfWealth",
  UtilityBill = "UtilityBill",
}

/**
 * Document verification type
 */
export enum DocumentVerificationType {
  Address = "Address",
  Age = "Age",
  Email = "Email",
  Identity = "Identity",
  PhoneNumber = "PhoneNumber",
  SourceOfWealth = "SourceOfWealth",
}

export enum FundingOption {
  Dividends = "Dividends",
  Inheritance = "Inheritance",
  Other = "Other",
  Salary = "Salary",
  SaleOfCompany = "SaleOfCompany",
  SaleOfProperty = "SaleOfProperty",
}

/**
 * Name of game provider.
 */
export enum GameProvider {
  Authentic = "Authentic",
  Betsoft = "Betsoft",
  Booming = "Booming",
  Elk = "Elk",
  Evolution = "Evolution",
  Ezugi = "Ezugi",
  Gaming1x2 = "Gaming1x2",
  ISoftBet = "ISoftBet",
  Microgaming = "Microgaming",
  Netent = "Netent",
  Nolimit = "Nolimit",
  Pariplay = "Pariplay",
  PlayNGo = "PlayNGo",
  Pragmatic = "Pragmatic",
  Quickspin = "Quickspin",
  Relax = "Relax",
  Scientific = "Scientific",
  Swintt = "Swintt",
  Thunderkick = "Thunderkick",
  Wazdan = "Wazdan",
  Yggdrasil = "Yggdrasil",
}

/**
 * The order to list game rounds.
 */
export enum GameRoundSearchOrder {
  brand = "brand",
  closingBalance = "closingBalance",
  completedAt = "completedAt",
  currency = "currency",
  gameId = "gameId",
  gameProvider = "gameProvider",
  gameRoundId = "gameRoundId",
  lastProcessedAt = "lastProcessedAt",
  playerId = "playerId",
  playerSessionId = "playerSessionId",
}

/**
 * The status of a game round.
 */
export enum GameRoundStatus {
  Loss = "Loss",
  Pending = "Pending",
  Win = "Win",
}

/**
 * Granularity level for stats reports
 */
export enum GranularityEnum {
  All = "All",
  Day = "Day",
  Hour = "Hour",
  Minute = "Minute",
  Month = "Month",
  Quarter = "Quarter",
  Second = "Second",
  Week = "Week",
  Year = "Year",
}

/**
 * The initiator type.
 */
export enum InitiatorTypeEnum {
  Agent = "Agent",
  Player = "Player",
}

/**
 * Kyc Provider
 */
export enum KycProviderType {
  Eniro = "Eniro",
  Sofort = "Sofort",
  SumSub = "SumSub",
  Trustly = "Trustly",
  Zimpler = "Zimpler",
}

/**
 * KYC Flow verification type
 */
export enum KycVerificationType {
  Failed = "Failed",
  Initialized = "Initialized",
  Pending = "Pending",
  Succeed = "Succeed",
}

/**
 * The order to list limit search results.
 */
export enum LimitSearchOrder {
  requestedAt = "requestedAt",
}

/**
 * Status of the limit.
 */
export enum LimitStatusEnum {
  LimitRemoved = "LimitRemoved",
  LimitSet = "LimitSet",
  PendingLimitCancelled = "PendingLimitCancelled",
  PendingLimitSet = "PendingLimitSet",
  PendingRemovalCancelled = "PendingRemovalCancelled",
}

/**
 * Type of limit.
 */
export enum LimitType {
  Deposit = "Deposit",
  Loss = "Loss",
  Session = "Session",
  Wager = "Wager",
}

/**
 * The limit period type.
 */
export enum LimitTypeEnum {
  Day = "Day",
  Month = "Month",
  Week = "Week",
}

/**
 * A sorting order specification.
 */
export enum OrderingSpecType {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

/**
 * Organization name.
 */
export enum OrgEnum {
  DT = "DT",
  Dummy = "Dummy",
  IN = "IN",
  MT = "MT",
}

/**
 * The order to list Payment search results
 */
export enum PaymentSearchOrder {
  brand = "brand",
  playerId = "playerId",
  processedAt = "processedAt",
  provider = "provider",
}

/**
 * Status of a payment.
 */
export enum PaymentStatus {
  Cancelled = "Cancelled",
  Completed = "Completed",
  Initialized = "Initialized",
}

/**
 * Type of a payment
 */
export enum PaymentType {
  Deposit = "Deposit",
  Withdrawal = "Withdrawal",
}

/**
 * The field by which to order the search results.
 */
export enum PlayerComplianceHistorySearchOrder {
  globalPlayerId = "globalPlayerId",
  playerId = "playerId",
  processedAt = "processedAt",
}

/**
 * The compliance status of a player
 */
export enum PlayerComplianceStatus {
  FormSubmitted = "FormSubmitted",
  Pending = "Pending",
  PendingDocuments = "PendingDocuments",
  Rejected = "Rejected",
  UploadedDocuments = "UploadedDocuments",
  Verified = "Verified",
}

/**
 * The order to list player kyc history results
 */
export enum PlayerKycHistorySearchOrder {
  kycProvider = "kycProvider",
  processedAt = "processedAt",
}

/**
 * Status of a reward for a given player
 */
export enum PlayerRewardStatus {
  Assigned = "Assigned",
  Claimed = "Claimed",
  Expired = "Expired",
  Removed = "Removed",
}

/**
 * The order to list player search results.
 */
export enum PlayerSearchOrder {
  balance = "balance",
  brand = "brand",
  city = "city",
  countryCode = "countryCode",
  dateOfBirth = "dateOfBirth",
  firstName = "firstName",
  lastLoginAt = "lastLoginAt",
  lastName = "lastName",
  phoneNumber = "phoneNumber",
  playerId = "playerId",
  registeredAt = "registeredAt",
  zipCode = "zipCode",
}

/**
 * Priorities.
 */
export enum PriorityEnum {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}

/**
 * Registration status: Registered or NotRegistered
 */
export enum RegistrationStatusFilter {
  PlayerNotRegistered = "PlayerNotRegistered",
  PlayerRegistered = "PlayerRegistered",
}

/**
 * Possible reward statuses
 */
export enum RewardStatus {
  Active = "Active",
  Inactive = "Inactive",
}

/**
 * Reward types
 */
export enum RewardTypeEnum {
  FreeSpinsType = "FreeSpinsType",
}

/**
 * The field by which to order the search results.
 */
export enum SearchRewardsOrderBy {
  createdAt = "createdAt",
  endsAt = "endsAt",
  name = "name",
  startsAt = "startsAt",
  status = "status",
}

/**
 * The order to list session search results.
 */
export enum SessionSearchOrder {
  cause = "cause",
  countryCode = "countryCode",
  createdAt = "createdAt",
  status = "status",
}

/**
 * The status of a session.
 */
export enum SessionStatus {
  Created = "Created",
  Destroyed = "Destroyed",
}

/**
 * Possible errors when loading statistics.
 */
export enum StatsError {
  TIMEOUT = "TIMEOUT",
  UNAVAILABLE = "UNAVAILABLE",
}

/**
 * The termination cause.
 */
export enum TerminationCause {
  CreatedByAgent = "CreatedByAgent",
  FailedLoginAttempt = "FailedLoginAttempt",
  LimitExceeded = "LimitExceeded",
  LoggedInFromAnotherDevice = "LoggedInFromAnotherDevice",
  LoggedOut = "LoggedOut",
  PlayerInactivated = "PlayerInactivated",
  PlayerInactivity = "PlayerInactivity",
  ResponsibleGaming = "ResponsibleGaming",
  TerminatedBySystem = "TerminatedBySystem",
}

/**
 * The order to list transaction search results.
 */
export enum TransactionSearchOrder {
  amount = "amount",
  brand = "brand",
  gameId = "gameId",
  gameProvider = "gameProvider",
  gameRoundId = "gameRoundId",
  playerId = "playerId",
  processedAt = "processedAt",
}

/**
 * Type of a transaction.
 */
export enum TransactionType {
  AdjustmentDeposit = "AdjustmentDeposit",
  AdjustmentWithdrawal = "AdjustmentWithdrawal",
  CancelDeposit = "CancelDeposit",
  CancelWithdrawal = "CancelWithdrawal",
  Cashback = "Cashback",
  CasinoBet = "CasinoBet",
  CasinoDeposit = "CasinoDeposit",
  CasinoWin = "CasinoWin",
  Deposit = "Deposit",
  InitDeposit = "InitDeposit",
  InitRollbackDeposit = "InitRollbackDeposit",
  InitRollbackWithdrawal = "InitRollbackWithdrawal",
  InitWithdrawal = "InitWithdrawal",
  JackpotWin = "JackpotWin",
  NegativeBet = "NegativeBet",
  RollbackCasinoBet = "RollbackCasinoBet",
  RollbackCasinoWin = "RollbackCasinoWin",
  RollbackDeposit = "RollbackDeposit",
  RollbackJackpotWin = "RollbackJackpotWin",
  RollbackWithdrawal = "RollbackWithdrawal",
  Withdrawal = "Withdrawal",
}

/**
 * Verification decision for Source of Wealth
 */
export enum VerificationDecision {
  Approved = "Approved",
  Rejected = "Rejected",
}

export interface AffiliateParameters {
  token: AffiliateToken;
  id: AffiliateId;
}

/**
 * A statistics column value sorting order specification.
 */
export interface ColumnOrderSpecType {
  column: string;
  order: OrderingSpecType;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
