import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { useGetPlayerRewardsPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Date as DateComponent,
  Value,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@/components/Tabs';
import { PlayerRewardStatus } from '@/globalTypes';
import { SanityRewardDetailBlockFragment } from './__generated__/SanityRewardDetailBlockFragment';
import useRewardDetail from './useRewardDetail';

export const Fragment = graphql`
  fragment SanityRewardDetailBlockFragment on SanityRewardDetailBlock {
    title {
      ...LocaleString
    }
  }
`;

const RewardDetailBlock: FC<{
  block: SanityRewardDetailBlockFragment;
}> = () => {
  const getPlayerRewardsLink = useGetPlayerRewardsPageLink();
  const params = useParams();
  const {
    reward,
    rewardPlayers,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = useRewardDetail(params.rewardId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'rewardDetail',
    data: rewardPlayers,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'updatedAt',
        title: 'Updated At',
        fromFilterField: 'updatedAtFrom',
        toFilterField: 'updatedAtTo',
      }),
      s.stringValue({
        field: 'playerId',
        title: 'Player Id',
        linkTo: ({ row }) => getPlayerRewardsLink(row.id) || '',
      }),
      s.enumValue({
        field: 'status',
        title: 'Status',
        filterField: 'rewardStatus',
        e: PlayerRewardStatus,
      }),
      s.stringValue({
        field: 'removedBy',
        title: 'Removed by',
        getValue: ({ row }) =>
          row.removedBy?.firstName + ' ' + row.removedBy?.lastName,
      }),
      s.stringValue({
        field: 'reason',
        title: 'Reason',
      }),
    ],
  });

  const tabIndex =
    Number(new URLSearchParams(window.location.search).get('indexTab')) || 0;

  return (
    <Card size="lg" title="Reward" showOptionsAtBottom>
      <CardBody>
        <Tabs defaultIndex={tabIndex}>
          <TabList>
            <Tab>Info</Tab>
            <Tab>Players</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div className="p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                <Value fetching={fetching} title="Name" value={reward?.name} />
                <Value
                  fetching={fetching}
                  title="Type"
                  value={reward?.rewardType?.name}
                />
                <Value
                  fetching={fetching}
                  title="Created By"
                  value={reward?.createdBy?.firstName}
                />
                <Value title="Created At">
                  <DateComponent copyable date={reward?.createdAt} />
                </Value>
                <Value
                  fetching={fetching}
                  title="Status"
                  value={reward?.status}
                />
                <Value
                  fetching={fetching}
                  title="Game Name"
                  value={reward?.rewardType?.gameDescriptor?.json?.name}
                />
                <Value
                  fetching={fetching}
                  title="Bet Level"
                  value={String(reward?.rewardType?.betLevel)}
                />
                <Value
                  fetching={fetching}
                  title="Amount"
                  value={String(reward?.rewardType?.amount)}
                />
                <Value
                  fetching={fetching}
                  title="Currency"
                  value={reward?.rewardType?.currency}
                />
                <Value
                  fetching={fetching}
                  title="Value"
                  value={String(reward?.rewardType?.value)}
                />
                <Value title="Start Date">
                  <DateComponent copyable date={reward?.startsAt} />
                </Value>
                <Value title="End date">
                  <DateComponent copyable date={reward?.endsAt} />
                </Value>
              </div>
            </TabPanel>
            <TabPanel>
              <Card
                size="lg"
                title="Reward Players"
                showOptionsAtBottom
                options={
                  <CardOptions>
                    <CardOptionsButton
                      disabled={!previousPage}
                      onClick={() => previousPage && previousPage()}
                    >
                      <PreviousIcon />
                    </CardOptionsButton>
                    <CardOptionsButton
                      disabled={!nextPage}
                      onClick={() => nextPage && nextPage()}
                    >
                      <NextIcon />
                    </CardOptionsButton>
                    <GlobalCardOptions />
                    <CardOptionsButton
                      onClick={() => refresh({ requestPolicy: 'network-only' })}
                    >
                      <RefreshIcon />
                    </CardOptionsButton>
                  </CardOptions>
                }
              >
                <CardBody>
                  <DataGrid />
                </CardBody>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
};

export default RewardDetailBlock;
