import { CloseAccountCause } from '@/globalTypes';

export const closeAccountCauseMap: Record<CloseAccountCause, string> = {
  AbusiveBehaviour: 'Abusive Behaviour',
  BlockCustomerRequest: 'Block Customer Request',
  CloseCustomerRequest: 'Close Customer Request',
  ConfirmedFraud: 'Confirmed Fraud',
  ConfirmedPromotionAbuse: 'Confirmed Promotion Abuse',
  MigrationCause: 'Migration Cuase',
  MultipleAccounts: 'Multiple Accounts',
  Other: 'Other',
  PEPSanctionsListMatch: 'PEP Sanctions List Match',
  ResponsibleGaming: 'Responsible Gaming',
  SanctionsListMatch: 'Sanctions List Match',
  SuspectedFraud: 'Suspect Fraud',
  SuspectedPromotionAbuse: 'Suspected Promotion Abuse',
};
