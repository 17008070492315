import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import { startCase } from 'lodash';
import React, { FC } from 'react';
import { feedback } from 'react-feedbacker';
import { useQuery } from 'urql';

import { useFeedbackMessages } from '@/bits';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  Copy,
  Country,
  Date as DateComponent,
  InlineIconButton,
  InlineSpinner,
  OptionsMenu,
  Value,
} from '@/components';
import { EditIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerAccountStatusForm,
  PlayerEmailForm,
  PlayerPhoneNumberForm,
} from '@/forms';
import PlayerCountryForm from '@/forms/player-country-form/PlayerCountryForm';
import { AccountStatusEnum } from '@/globalTypes';
import formatCountryCode from '@/utils/formatter/formatCountryCode';
import formatPhoneNumber from '@/utils/formatter/formatPhoneNumber';
import {
  PlayerInfoBox,
  PlayerInfoBoxVariables,
} from './__generated__/PlayerInfoBox';
import { SanityPlayerInfoBlockFragment } from './__generated__/SanityPlayerInfoBlockFragment';
import useResendVerificationEmail from './useResendVerificationEmail';

export const Fragment = graphql`
  fragment SanityPlayerInfoBlockFragment on SanityPlayerInfoBlock {
    title {
      ...LocaleString
    }
    phoneNumber {
      ...LocaleString
    }
    email {
      ...LocaleString
    }
    edit {
      ...LocaleString
    }
    resendVerificationEmail {
      ...LocaleString
    }
    couldNotResendVerificationEmail {
      ...LocaleString
    }
    country {
      ...LocaleString
    }
    accountStatus {
      ...LocaleString
    }
    accountStatusOpen {
      ...LocaleString
    }
    accountStatusClosed {
      ...LocaleString
    }
    accountWillReopen {
      ...LocaleString
    }
    selfExclusion {
      ...LocaleString
    }
    excludedUntil {
      ...LocaleString
    }
    willBeCancelledAt {
      ...LocaleString
    }
    lastLogin {
      ...LocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerInfoBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      rawPlayerId
      email
      isEmailVerified
      phoneNumber
      isPhoneNumberVerified
      countryCode
      accountStatus {
        status
        cause
      }
      shouldReopenAt
      selfExclusionDetails {
        id
        exclusionEndsAt
        willBeCancelledAt
      }
      loginInfo {
        lastLogin {
          loggedAt
          countryCode
        }
      }
    }
  }
`;

const PlayerInfoBlock: FC<{
  block: SanityPlayerInfoBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();
  const feedbackMessages = useFeedbackMessages();
  const [, resendVerificationEmail] = useResendVerificationEmail();

  const [{ data, fetching }, refresh] = useQuery<
    PlayerInfoBox,
    PlayerInfoBoxVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  return (
    <Card
      size="lg"
      title={
        <>
          {t(block.title)}{' '}
          {fetching ? (
            <InlineSpinner />
          ) : (
            <Copy value={data?.player.rawPlayerId} />
          )}
        </>
      }
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          <Value
            fetching={fetching}
            title={t(block.phoneNumber)}
            value={formatPhoneNumber(data?.player?.phoneNumber)}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerPhoneNumberForm playerId={data?.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          >
            {`${data?.player?.phoneNumber || '-'} ${
              data?.player?.isPhoneNumberVerified ? '✅' : '❌'
            } `}
          </Value>
          <Value
            fetching={fetching}
            title={t(block.email)}
            value={data?.player?.email}
            suffix={
              <OptionsMenu
                items={[
                  {
                    content: t(block.edit),
                    modalContent: data?.player.id && (
                      <PlayerEmailForm playerId={data.player.id} />
                    ),
                    icon: <EditIcon />,
                  },
                  {
                    content: t(block.resendVerificationEmail),
                    icon: <RefreshIcon />,
                    isHidden:
                      !data?.player.email || data.player.isEmailVerified,
                    shouldConfirm: true,
                    onClick: () => {
                      if (!data?.player.id) {
                        return;
                      }
                      resendVerificationEmail({
                        playerId: data.player.id,
                      }).then((res) => {
                        if (res.data?.resendVerificationEmail.playerId) {
                          return feedback.success(feedbackMessages.success);
                        }
                        feedback.error(
                          t(block.couldNotResendVerificationEmail),
                        );
                      });
                    },
                  },
                ]}
              />
            }
          >
            {`${data?.player?.email || '-'} ${
              data?.player?.isEmailVerified ? '✅' : '❌'
            } `}
          </Value>
          <Value fetching={fetching} title={t(block.country)}>
            <Country countryCode={data?.player?.countryCode} />
            <ControlledModal
              content={
                data?.player.id ? (
                  <PlayerCountryForm playerId={data.player.id} />
                ) : null
              }
            >
              <InlineIconButton>
                <EditIcon />
              </InlineIconButton>
            </ControlledModal>
          </Value>
          <Value fetching={fetching} title={t(block.accountStatus)}>
            {data?.player?.accountStatus.status === AccountStatusEnum.Open
              ? t(block.accountStatusOpen)
              : `${t(block.accountStatusClosed)} (${startCase(
                  data?.player?.accountStatus.cause || '',
                )})`}
            {data?.player?.shouldReopenAt && (
              <>
                <br />
                {t(block.accountWillReopen)}{' '}
                <DateComponent copyable date={data?.player?.shouldReopenAt} />
              </>
            )}
            <ControlledModal
              content={
                data?.player.id ? (
                  <PlayerAccountStatusForm playerId={data?.player.id} />
                ) : null
              }
            >
              <InlineIconButton>
                <EditIcon />
              </InlineIconButton>
            </ControlledModal>
          </Value>
          <Value fetching={fetching} title={t(block.selfExclusion)}>
            {!data?.player?.selfExclusionDetails?.willBeCancelledAt &&
              data?.player?.selfExclusionDetails?.exclusionEndsAt && (
                <div>
                  {t(block.excludedUntil)}{' '}
                  <DateComponent
                    copyable
                    date={data?.player?.selfExclusionDetails?.exclusionEndsAt}
                  />
                </div>
              )}
            {data?.player?.selfExclusionDetails?.willBeCancelledAt && (
              <div>
                {t(block.willBeCancelledAt)}{' '}
                <DateComponent
                  copyable
                  date={data?.player?.selfExclusionDetails?.willBeCancelledAt}
                />
              </div>
            )}
            {!data?.player?.selfExclusionDetails?.willBeCancelledAt &&
              !data?.player?.selfExclusionDetails?.exclusionEndsAt &&
              '-'}
          </Value>
          <Value fetching={fetching} title={t(block.lastLogin)}>
            {formatCountryCode(data?.player?.loginInfo?.lastLogin?.countryCode)}{' '}
            <DateComponent
              copyable
              date={data?.player?.loginInfo?.lastLogin?.loggedAt}
            />
          </Value>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerInfoBlock;
