import { Link, graphql } from 'gatsby';
import React, { FC } from 'react';

import { Breadcrumb, Breadcrumbs as BreadcrumbsComponent } from '@/components';
import { usePage } from '@/contexts';
import { useGetOperatorPath } from '@/utils/operators';
import { SanityBreadcrumbsBlockFragment } from './__generated__/SanityBreadcrumbsBlockFragment';

export const Fragment = graphql`
  fragment SanityBreadcrumbsBlockFragment on SanityBreadcrumbsBlock {
    pages {
      _id
      title
      path {
        current
      }
    }
  }
`;

const BreadcrumbsBlock: FC<{
  block: SanityBreadcrumbsBlockFragment;
}> = ({ block }) => {
  const getOperatorPath = useGetOperatorPath();

  const currentPage = usePage();

  return (
    <BreadcrumbsComponent>
      {block.pages?.map((page) => (
        <Breadcrumb key={page?._id}>
          <Link to={getOperatorPath(page?.path?.current) || ''}>
            {page?.title}
          </Link>
        </Breadcrumb>
      ))}
      <Breadcrumb last>{currentPage?.title}</Breadcrumb>
    </BreadcrumbsComponent>
  );
};

export default BreadcrumbsBlock;
