import { saveAs } from 'file-saver';
import { gql, useClient } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import {
  PlayerPaymentsReport,
  PlayerPaymentsReportVariables,
} from './__generated__/PlayerPaymentsReport';
import { queryParams } from './queryParams';

const PLAYER_PAYMENTS_REPORT_QUERY = gql`
  query PlayerPaymentsReport(
    $id: String
    $rawId: String
    $playerId: ID!
    $sessionId: String
    $brand: BrandEnum
    $org: OrgEnum
    $paymentType: PaymentType
    $paymentStatus: PaymentStatus
    $currency: String
    $gameProviderId: GameProvider
    $from: OffsetDateTime
    $to: OffsetDateTime
    $orderBy: PaymentSearchOrder
    $desc: Boolean
  ) {
    viewer {
      id
      exportedPayments(
        id: $id
        rawId: $rawId
        playerId: $playerId
        sessionId: $sessionId
        brand: $brand
        org: $org
        paymentType: $paymentType
        paymentStatus: $paymentStatus
        currency: $currency
        gameProviderId: $gameProviderId
        from: $from
        to: $to
        orderBy: $orderBy
        desc: $desc
      ) {
        url
      }
    }
  }
`;

export default function usePlayerPaymentsReport(playerId: string) {
  const [query] = useQueryParams(queryParams);
  const client = useClient();

  const generate = async () => {
    const variables: PlayerPaymentsReportVariables = {
      ...mapVariables(query),
      playerId,
    };

    return client
      .query<PlayerPaymentsReport, PlayerPaymentsReportVariables>(
        PLAYER_PAYMENTS_REPORT_QUERY,
        variables,
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.exportedPayments.url;

        if (url) {
          saveAs(url, 'player-payments-report.csv');
        }
      });
  };

  return {
    generate,
  };
}
