import { useParams } from '@reach/router';
import Tippy from '@tippyjs/react';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useCallback } from 'react';
import { useQuery } from 'urql';

import {
  LimitPeriodDetailList,
  limitPeriodDetailList_periodLimit,
} from '@/bits/limit-period-detail-list/component';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ConditionalWrap,
  ControlledModal,
  InlineIconButton,
} from '@/components';
import { Bar } from '@/components/Bar';
import {
  CloseIcon,
  EditIcon,
  InformationIcon,
  RefreshIcon,
} from '@/components/icons';
import { TrashIcon } from '@/components/icons/TrashIcon';
import { useTranslate } from '@/contexts';
import { LocaleString } from '@/contexts/__generated__/LocaleString';
import PlayerCancelDepositLimitForm from '@/forms/player-cancel-deposit-limit-form/PlayerCancelDepositLimitForm';
import PlayerDepositLimitsForm from '@/forms/player-deposit-limits-form/PlayerDepositLimitsForm';
import PlayerRemoveDepositLimitForm from '@/forms/player-remove-deposit-limit-form/PlayerRemoveDepositLimitForm';
import { limitFragment } from '@/fragments/Limits';
import { LimitType, LimitTypeEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import formatDate from '@/utils/formatter/formatDate';
import formatMoney from '@/utils/formatter/formatMoney';
import {
  PlayerDepositLimitsBox,
  PlayerDepositLimitsBoxVariables,
  PlayerDepositLimitsBox_player_wallet_depositLimit_dayLimit,
  PlayerDepositLimitsBox_player_wallet_depositLimit_monthLimit,
  PlayerDepositLimitsBox_player_wallet_depositLimit_weekLimit,
} from './__generated__/PlayerDepositLimitsBox';
import { SanityPlayerDepositLimitsBlockFragment } from './__generated__/SanityPlayerDepositLimitsBlockFragment';

type LimitPeriod =
  | PlayerDepositLimitsBox_player_wallet_depositLimit_dayLimit
  | PlayerDepositLimitsBox_player_wallet_depositLimit_weekLimit
  | PlayerDepositLimitsBox_player_wallet_depositLimit_monthLimit;

export const Fragment = graphql`
  fragment SanityPlayerDepositLimitsBlockFragment on SanityPlayerDepositLimitsBlock {
    title {
      ...LocaleString
    }
    depositLimitsLabel {
      ...LocaleString
    }
    dailyLabel {
      ...LocaleString
    }
    weeklyLabel {
      ...LocaleString
    }
    monthlyLabel {
      ...LocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerDepositLimitsBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      wallet {
        id
        currency
        depositLimit {
          dayLimit {
            ...LimitFragment
            ...LimitPeriodDetailList_periodLimit
          }
          weekLimit {
            ...LimitFragment
            ...LimitPeriodDetailList_periodLimit
          }
          monthLimit {
            ...LimitFragment
            ...LimitPeriodDetailList_periodLimit
          }
        }
      }
    }
  }
  ${limitFragment}
  ${limitPeriodDetailList_periodLimit}
`;

const DepositLimitBar: FC<{
  playerId?: string;
  fetching: boolean;
  limit: Nullable<LimitPeriod>;
  currency: Nullable<string>;
  label: Nullable<LocaleString>;
  limitPeriodType: LimitTypeEnum;
}> = ({ fetching, limit, currency, label, playerId, limitPeriodType }) => {
  const { t } = useTranslate();

  const currencyValueFormatter = useCallback(
    (value: Nullable<number>) => formatMoney(value, currency),
    [currency],
  );

  return (
    <div>
      <div className="flex">
        <ConditionalWrap
          condition={!!limit}
          wrap={(children) => (
            <Tippy
              content={`Period ending: ${formatDate(
                limit?.currentPeriodEndTime,
              )}`}
            >
              <div className="w-full">{children}</div>
            </Tippy>
          )}
        >
          <Bar
            loading={fetching}
            value={
              ((limit?.value ?? 0) - (limit?.available ?? 0)) /
              (limit?.value ?? 0)
            }
          >
            {t(label, {
              value: formatMoney((limit?.value ?? 0) - (limit?.available ?? 0)),
              of: formatMoney(limit?.value, currency),
            })}
          </Bar>
        </ConditionalWrap>
        <ControlledModal
          content={
            playerId && limit ? (
              <LimitPeriodDetailList
                limit={limit}
                limitTypeTitle={LimitType.Deposit}
                limitPeriodType={limitPeriodType}
                formatValue={currencyValueFormatter}
              />
            ) : null
          }
        >
          <InlineIconButton disabled={!limit}>
            <InformationIcon />
          </InlineIconButton>
        </ControlledModal>
        <ControlledModal
          content={
            playerId ? (
              <PlayerDepositLimitsForm
                playerId={playerId}
                limitPeriodType={limitPeriodType}
                currency={currency}
                limit={limit}
              />
            ) : null
          }
        >
          <InlineIconButton>
            <EditIcon />
          </InlineIconButton>
        </ControlledModal>
        <ControlledModal
          content={
            playerId ? (
              <PlayerRemoveDepositLimitForm
                playerId={playerId}
                limitPeriodType={limitPeriodType}
              />
            ) : null
          }
        >
          <InlineIconButton>
            <TrashIcon />
          </InlineIconButton>
        </ControlledModal>
      </div>
      {limit?.pendingPeriodLimit && (
        <div>
          Limit will be updated to{' '}
          {formatMoney(limit.pendingPeriodLimit.value, currency)} on{' '}
          {formatDate(limit.pendingPeriodLimit.validFrom)}
          <ControlledModal
            content={
              playerId ? (
                <PlayerCancelDepositLimitForm
                  playerId={playerId}
                  limitPeriodType={limitPeriodType}
                />
              ) : null
            }
          >
            <InlineIconButton>
              <CloseIcon />
            </InlineIconButton>
          </ControlledModal>
        </div>
      )}
      {limit?.pendingPeriodLimitRemoval && (
        <div>
          Limit will be removed on{' '}
          {formatDate(limit.pendingPeriodLimitRemoval.validFrom)}
        </div>
      )}
    </div>
  );
};

const PlayerDepositLimitsBlock: FC<{
  block: SanityPlayerDepositLimitsBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();

  const [{ data, fetching }, refresh] = useQuery<
    PlayerDepositLimitsBox,
    PlayerDepositLimitsBoxVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          <div className="col-span-full">
            <label className="text-sm text-gray-500 font-semibold">
              {t(block.depositLimitsLabel)}
            </label>
            <div className="grid gap-2 pt-1">
              <DepositLimitBar
                currency={data?.player.wallet?.currency}
                fetching={fetching}
                limit={data?.player.wallet?.depositLimit?.dayLimit}
                label={block.dailyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Day}
              />
              <DepositLimitBar
                currency={data?.player.wallet?.currency}
                fetching={fetching}
                limit={data?.player.wallet?.depositLimit?.weekLimit}
                label={block.weeklyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Week}
              />
              <DepositLimitBar
                currency={data?.player.wallet?.currency}
                fetching={fetching}
                limit={data?.player.wallet?.depositLimit?.monthLimit}
                label={block.monthlyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Month}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerDepositLimitsBlock;
