import { useOktaAuth } from '@okta/okta-react';
import React, { FC, ReactElement, useEffect, useRef } from 'react';

const SecureRoute: FC = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const pendingLogin = useRef(false);

  useEffect(() => {
    if (authState.isAuthenticated) {
      pendingLogin.current = false;
      return;
    }

    // Start login if app has decided it is not logged in and there is no pending signin
    if (
      !authState.isAuthenticated &&
      !authState.isPending &&
      !pendingLogin.current
    ) {
      pendingLogin.current = true;
      oktaAuth.setOriginalUri();

      oktaAuth.signInWithRedirect();
    }
  }, [authState.isPending, authState.isAuthenticated, oktaAuth]);

  if (!authState.isAuthenticated) {
    return null;
  }

  return children as ReactElement;
};

export const Secure: FC = ({ children }) => {
  if (typeof window === 'undefined') {
    return null;
  }
  return <SecureRoute>{children}</SecureRoute>;
};
