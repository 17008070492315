import {
  BooleanParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import {
  AmlRiskLevelParam,
  PlayerComplianceStatusParam,
} from '@/utils/query/params';

export const queryParams = {
  after: StringParam,
  amlLevel: AmlRiskLevelParam,
  before: StringParam,
  complianceStatus: PlayerComplianceStatusParam,
  desc: withDefault(BooleanParam, true),
  first: NumberParam,
  last: NumberParam,
};
