import { useParams } from '@reach/router';
import Tippy from '@tippyjs/react';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerComplianceHistorySearchOrder } from '@/globalTypes';
import { assert } from '@/utils/error';
import formatMoney from '@/utils/formatter/formatMoney';
import { SanityPlayerSowHistoryBlockFragment } from './__generated__/SanityPlayerSowHistoryBlockFragment';
import usePlayerSowHistory from './usePlayerSowHistory';

const SOWTypesTranslations: Record<string, string> = {
  SubmittedSourceOfWealthEvent: 'Submitted SOW',
  SourceOfWealthVerifiedEvent: 'Source Verified',
  FundingDocumentsRequestedEvent: 'Funding Documents Requested',
  SourceOfWealthRequiredEvent: 'SOW Required',
  AmlRiskLevelChangedEvent: 'AML Risk Level Changed',
};

export const Fragment = graphql`
  fragment SanityPlayerSowHistoryBlockFragment on SanityPlayerSowHistoryBlock {
    title {
      ...LocaleString
    }
  }
`;

const PlayerSowHistoryBlock: FC<{
  block: SanityPlayerSowHistoryBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  assert(params.playerId, 'missing playerId');

  const {
    history,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerSowHistory(params.playerId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerSowHistory',
    data: history,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'processedAt',
        title: 'Time',
        fromFilterField: 'from',
        toFilterField: 'to',
        orderBy: PlayerComplianceHistorySearchOrder.processedAt,
      }),
      s.initiatorValue({
        field: 'initiator',
        title: 'Initiator',
      }),
      s.stringValue({
        field: 'eventType',
        title: 'Event Type',
        filterField: 'eventType',
        getValue: ({ row }) => (
          <Tippy content={row.eventType}>
            <span>{SOWTypesTranslations[row.eventType] ?? row.eventType}</span>
          </Tippy>
        ),
      }),
      s.stringValue({
        field: 'outcome',
        title: 'Outcome',
        wrap: ({ row }) => {
          const sow = ('sourceOfWealth' in row && row.sourceOfWealth) || null;
          return (
            <ul>
              {'decision' in row && row.decision && (
                <li>Decision: {row.decision}</li>
              )}
              {'amlStatus' in row && row.amlStatus && (
                <li>AML Status: {row.amlStatus}</li>
              )}
              {sow?.fundingOptions && (
                <li>Funding Options: {sow?.fundingOptions.join(', ')}</li>
              )}
              {sow?.salaryLowerBound && (
                <li>
                  Salary Bound: {formatMoney(sow.salaryLowerBound)}
                  {sow.salaryUpperBound
                    ? ` - ${formatMoney(sow.salaryUpperBound)}`
                    : ''}
                </li>
              )}
              {sow?.expendingLowerBound && (
                <li>
                  Expending Bound: {formatMoney(sow.expendingLowerBound)}
                  {sow?.expendingUpperBound
                    ? ` - ${formatMoney(sow?.expendingUpperBound)}`
                    : ''}
                </li>
              )}
              {sow?.nationality && <li>Nationality: {sow.nationality}</li>}
              {sow?.otherFundingDescription && (
                <li>Description: {sow?.otherFundingDescription}</li>
              )}
              {'requestedFundingDocuments' in row &&
                row.requestedFundingDocuments.length && (
                  <li>
                    Requested documents:{' '}
                    {row.requestedFundingDocuments.join(', ')}
                  </li>
                )}
              {'amlStatus' in row && row.amlStatus && (
                <li>AML Status: {row.amlStatus}</li>
              )}
              {'amlRiskLevel' in row && row.amlRiskLevel && (
                <li>New AML Risk Level: {row.amlRiskLevel}</li>
              )}
              {'reason' in row && row.reason && (
                <li>AML Risk level Reason: {row.reason}</li>
              )}
            </ul>
          );
        },
      }),
    ],
  });
  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerSowHistoryBlock;
