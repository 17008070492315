import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import {
  LimitPeriodDetailList,
  limitPeriodDetailList_periodLimit,
} from '@/bits/limit-period-detail-list/component';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ConditionalWrap,
  ControlledModal,
  InlineIconButton,
  Tippy,
} from '@/components';
import { Bar } from '@/components/Bar';
import {
  CloseIcon,
  EditIcon,
  InformationIcon,
  RefreshIcon,
} from '@/components/icons';
import { TrashIcon } from '@/components/icons/TrashIcon';
import { useTranslate } from '@/contexts';
import { LocaleString } from '@/contexts/__generated__/LocaleString';
import {
  PlayerCancelSessionLimitForm,
  PlayerRemoveSessionLimitForm,
  PlayerSessionLimitsForm,
} from '@/forms';
import { limitFragment } from '@/fragments/Limits';
import { LimitType, LimitTypeEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import formatDate from '@/utils/formatter/formatDate';
import formatDuration from '@/utils/formatter/formatDuration';
import {
  PlayerSessionLimitsBox,
  PlayerSessionLimitsBoxVariables,
  PlayerSessionLimitsBox_player_wallet_sessionLimit_dayLimit,
  PlayerSessionLimitsBox_player_wallet_sessionLimit_monthLimit,
  PlayerSessionLimitsBox_player_wallet_sessionLimit_weekLimit,
} from './__generated__/PlayerSessionLimitsBox';
import { SanityPlayerSessionLimitsBlockStaticQuery } from './__generated__/SanityPlayerSessionLimitsBlockStaticQuery';

type LimitPeriod =
  | PlayerSessionLimitsBox_player_wallet_sessionLimit_dayLimit
  | PlayerSessionLimitsBox_player_wallet_sessionLimit_weekLimit
  | PlayerSessionLimitsBox_player_wallet_sessionLimit_monthLimit;

const BLOCK_STATIC_QUERY = graphql`
  query SanityPlayerSessionLimitsBlockStaticQuery {
    sanityPlayerSessionLimitsBlock {
      title {
        ...LocaleString
      }
      sessionLimitsLabel {
        ...LocaleString
      }
      dailyLabel {
        ...LocaleString
      }
      weeklyLabel {
        ...LocaleString
      }
      monthlyLabel {
        ...LocaleString
      }
    }
  }
`;

const QUERY = gql`
  query PlayerSessionLimitsBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      wallet {
        id
        currency
        sessionLimit {
          dayLimit {
            ...LimitFragment
            ...LimitPeriodDetailList_periodLimit
          }
          weekLimit {
            ...LimitFragment
            ...LimitPeriodDetailList_periodLimit
          }
          monthLimit {
            ...LimitFragment
            ...LimitPeriodDetailList_periodLimit
          }
        }
      }
    }
  }
  ${limitFragment}
  ${limitPeriodDetailList_periodLimit}
`;

const SessionLimitBar: FC<{
  playerId?: string;
  fetching: boolean;
  limit: Nullable<LimitPeriod>;
  label: Nullable<LocaleString>;
  limitPeriodType: LimitTypeEnum;
}> = ({ fetching, limit, label, playerId, limitPeriodType }) => {
  const { t } = useTranslate();

  return (
    <div>
      <div className="flex">
        <ConditionalWrap
          condition={!!limit}
          wrap={(children) => (
            <Tippy
              content={`Period ending: ${formatDate(
                limit?.currentPeriodEndTime,
              )}`}
            >
              <div className="w-full">{children}</div>
            </Tippy>
          )}
        >
          <Bar
            loading={fetching}
            value={
              ((limit?.value ?? 0) - (limit?.available ?? 0)) /
              (limit?.value ?? 0)
            }
          >
            {t(label, {
              value: formatDuration(
                (limit?.value ?? 0) - (limit?.available ?? 0),
              ),
              of: formatDuration(limit?.value),
            })}
          </Bar>
        </ConditionalWrap>
        <ControlledModal
          content={
            playerId && limit ? (
              <LimitPeriodDetailList
                limit={limit}
                limitTypeTitle={LimitType.Session}
                limitPeriodType={limitPeriodType}
                formatValue={formatDuration}
              />
            ) : null
          }
        >
          <InlineIconButton disabled={!limit}>
            <InformationIcon />
          </InlineIconButton>
        </ControlledModal>
        <ControlledModal
          content={
            playerId ? (
              <PlayerSessionLimitsForm
                playerId={playerId}
                limitPeriodType={limitPeriodType}
                limit={limit}
              />
            ) : null
          }
        >
          <InlineIconButton>
            <EditIcon />
          </InlineIconButton>
        </ControlledModal>
        <ControlledModal
          content={
            playerId ? (
              <PlayerRemoveSessionLimitForm
                playerId={playerId}
                limitPeriodType={limitPeriodType}
              />
            ) : null
          }
        >
          <InlineIconButton>
            <TrashIcon />
          </InlineIconButton>
        </ControlledModal>
      </div>
      {limit?.pendingPeriodLimit && (
        <div>
          Limit will be updated to{' '}
          {formatDuration(limit.pendingPeriodLimit.value)} on{' '}
          {formatDate(limit.pendingPeriodLimit.validFrom)}
          <ControlledModal
            content={
              playerId ? (
                <PlayerCancelSessionLimitForm
                  playerId={playerId}
                  limitPeriodType={limitPeriodType}
                />
              ) : null
            }
          >
            <InlineIconButton>
              <CloseIcon />
            </InlineIconButton>
          </ControlledModal>
        </div>
      )}
      {limit?.pendingPeriodLimitRemoval && (
        <div>
          Limit will be removed on{' '}
          {formatDate(limit.pendingPeriodLimitRemoval.validFrom)}
        </div>
      )}
    </div>
  );
};

const PlayerSessionLimitsBlock: FC = () => {
  const { t } = useTranslate();
  const params = useParams();

  const block = useStaticQuery<SanityPlayerSessionLimitsBlockStaticQuery>(
    BLOCK_STATIC_QUERY,
  ).sanityPlayerSessionLimitsBlock;

  const [{ data, fetching }, refresh] = useQuery<
    PlayerSessionLimitsBox,
    PlayerSessionLimitsBoxVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  if (!block) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          <div className="col-span-full">
            <label className="text-sm text-gray-500 font-semibold">
              {t(block.sessionLimitsLabel)}
            </label>
            <div className="grid gap-2 pt-1">
              <SessionLimitBar
                fetching={fetching}
                limit={data?.player.wallet?.sessionLimit?.dayLimit}
                label={block.dailyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Day}
              />
              <SessionLimitBar
                fetching={fetching}
                limit={data?.player.wallet?.sessionLimit?.weekLimit}
                label={block.weeklyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Week}
              />
              <SessionLimitBar
                fetching={fetching}
                limit={data?.player.wallet?.sessionLimit?.monthLimit}
                label={block.monthlyLabel}
                playerId={data?.player.id}
                limitPeriodType={LimitTypeEnum.Month}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerSessionLimitsBlock;
