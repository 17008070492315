import gql from 'graphql-tag';
import { useQuery } from 'urql';

import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  Transactions,
  TransactionsVariables,
} from './__generated__/Transactions';
import { queryParams } from './queryParams';

const PLAYER_TRANSACTION_QUERY = gql`
  query Transactions(
    $adjustmentCategory: AdjustmentCategory
    $adjustmentType: AdjustmentType
    $after: String
    $before: String
    $brand: BrandEnum
    $currency: String
    $desc: Boolean
    $first: Int
    $from: OffsetDateTime
    $id: String
    $last: Int
    $maxAmount: PositiveBigDecimal
    $minAmount: PositiveBigDecimal
    $orderBy: TransactionSearchOrder
    $paymentMethod: String
    $paymentStatus: [PaymentStatus!]
    $playerId: ID
    $rawId: String
    $to: OffsetDateTime
    $transactionTypes: [TransactionType!]
  ) {
    viewer {
      id
      transactionsV2(
        adjustmentCategory: $adjustmentCategory
        adjustmentType: $adjustmentType
        after: $after
        before: $before
        brand: $brand
        currency: $currency
        desc: $desc
        first: $first
        from: $from
        id: $id
        last: $last
        maxAmount: $maxAmount
        minAmount: $minAmount
        orderBy: $orderBy
        paymentMethod: $paymentMethod
        paymentStatus: $paymentStatus
        playerId: $playerId
        rawId: $rawId
        to: $to
        transactionTypes: $transactionTypes
      ) {
        edges {
          node {
            rawTransactionId
            playerId
            transactionId
            gameId
            gameRoundId
            isRoundFinished
            extTransactionId
            payload
            gameSessionId
            gameProvider
            gameDescriptor {
              json
            }
            brand {
              name
            }
            processedAt
            paymentMethod
            paymentStatus
            transactionType
            openingBalance
            closingBalance
            currency
            comment
            amount
            initiator {
              ... on PlayerInitiator {
                player {
                  firstName
                  lastName
                  email
                  id
                }
              }
              ... on AgentInitiator {
                agent {
                  email
                  firstName
                  lastName
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function useTransactions() {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });

  const [{ data, fetching }, refresh] = useQuery<
    Transactions,
    TransactionsVariables
  >({
    query: PLAYER_TRANSACTION_QUERY,
    variables: mapVariables(query),
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => {
      setQuery(values, 'replaceIn');
    },
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.transactionsV2.pageInfo),
    transactions: data?.viewer.transactionsV2?.edges?.map((edge) => edge?.node),
  };
}
