import { gql, useQuery } from 'urql';

import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  LinkedAccounts,
  LinkedAccountsVariables,
} from './__generated__/LinkedAccounts';
import { queryParams } from './queryParams';

const QUERY = gql`
  query LinkedAccounts(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      allLinkedAccounts(
        after: $after
        before: $before
        first: $first
        last: $last
      ) {
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
        edges {
          node {
            playerId
            firstName
            lastName
            linkedAccounts {
              playerId
              numberOfLinks
            }
          }
        }
      }
    }
  }
`;

export default function useSourceOfWealth() {
  const [query, setQuery] = useQueryParamsWithTime(queryParams);

  const [{ data, fetching }, refresh] = useQuery<
    LinkedAccounts,
    LinkedAccountsVariables
  >({
    query: QUERY,
    variables: {
      first: 20,
      ...mapVariables(query),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.allLinkedAccounts.pageInfo),
    linkedAccounts: data?.viewer.allLinkedAccounts?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
