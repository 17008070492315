import { TimeValue } from '@/contexts';
import { GranularityEnum } from '@/globalTypes';
import {
  eachDayOfInterval,
  eachHourOfInterval,
  eachMinuteOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachSecondOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
} from '@/utils';

export const eachDatePointOfInterval = (time: TimeValue) => {
  const interval = { start: time.from, end: time.to };

  switch (time.granularity) {
    case GranularityEnum.Second:
      return eachSecondOfInterval(interval);
    case GranularityEnum.Minute:
      return eachMinuteOfInterval(interval);
    case GranularityEnum.Hour:
      return eachHourOfInterval(interval);
    case GranularityEnum.Day:
      return eachDayOfInterval(interval);
    case GranularityEnum.Week:
      return eachWeekOfInterval(interval);
    case GranularityEnum.Month:
      return eachMonthOfInterval(interval);
    case GranularityEnum.Quarter:
      return eachQuarterOfInterval(interval);
    case GranularityEnum.Year:
      return eachYearOfInterval(interval);
    case GranularityEnum.All:
      return [time.from];
    default:
      throw new Error(`no implementation for granularity: ${time.granularity}`);
  }
};
