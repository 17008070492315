import { useEffect, useState } from 'react';

const GQL_ENDPOINT_STORAGE_KEY = '__GraphQLEndpoint__';

declare global {
  interface Window {
    testUtils?: Record<string, unknown>;
  }
}

const registerTestUtils = (utils: Record<string, unknown>) => {
  if (process.env.APP_ENV !== 'development') {
    return;
  }

  if (!window.testUtils) {
    window.testUtils = {};
  }

  Object.entries(utils).forEach(([key, value]) => {
    if (window.testUtils) {
      window.testUtils[key] = value;
    }
  });
};

export const useDevelopmentGraphQLEndpoint = () => {
  const [endpoint, setEndpoint] = useState<string | null>(() => {
    if (process.env.APP_ENV !== 'development') {
      return null;
    }
    return sessionStorage.getItem(GQL_ENDPOINT_STORAGE_KEY);
  });

  useEffect(() => {
    registerTestUtils({
      setGraphQLEndpoint: (endpoint: string) => {
        sessionStorage.setItem(GQL_ENDPOINT_STORAGE_KEY, endpoint);
        setEndpoint(endpoint);
      },
      resetGraphQLEndpoint: () => {
        sessionStorage.removeItem(GQL_ENDPOINT_STORAGE_KEY);
        setEndpoint(null);
      },
    });
  }, []);

  return endpoint;
};
