import { sum } from 'lodash/fp';
import { gql } from 'urql';

import { AdjustmentSummary_stats } from './__generated__/AdjustmentSummary_stats';
import { DepositSummary_stats } from './__generated__/DepositSummary_stats';
import formatMoney from './formatter/formatMoney';

export const adjustmentSummary_stats = gql`
  fragment AdjustmentSummary_stats on StatsEntryType {
    amountAdjustments
    amountBonusAdjustments
    currency
  }
`;

export const depositSummary_stats = gql`
  fragment DepositSummary_stats on StatsEntryType {
    amountDeposits
    currency
  }
`;

type CurrencyAmountRecord = Record<string, number>;

type AnyOfQueryStats = AdjustmentSummary_stats | DepositSummary_stats;

const getAmountsByCurrencies = <StatsType extends AnyOfQueryStats>(
  stats: StatsType[] | undefined,
  getRowTotal: (row: StatsType) => number | null,
) => {
  return stats?.reduce<CurrencyAmountRecord>((acc, row) => {
    if (!row.currency) {
      return acc;
    }

    const old = acc[row.currency] ?? 0;
    const rowTotal = getRowTotal(row);

    return {
      ...acc,
      [row.currency]: sum([old, rowTotal]),
    };
  }, {});
};

export const getDepositsSummaryRecord = (
  stats: DepositSummary_stats[] | undefined,
): CurrencyAmountRecord | undefined => {
  return getAmountsByCurrencies(stats, (row) => row.amountDeposits);
};

export const getAdjustmentsSummaryRecord = (
  stats: AdjustmentSummary_stats[] | undefined,
): CurrencyAmountRecord | undefined => {
  return getAmountsByCurrencies(stats, (row) =>
    sum([row.amountAdjustments, row.amountBonusAdjustments]),
  );
};

export const formatCurrencyAmountRecord = (
  record: CurrencyAmountRecord | undefined,
) => {
  if (!record) {
    return undefined;
  }

  return Object.entries(record)
    .map(([currency, value]) => formatMoney(value, currency))
    .join(', ');
};
