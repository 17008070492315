import { TippyProps } from '@tippyjs/react';
import React, {
  CSSProperties,
  MouseEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Tippy } from '@/components';
import { Nullable } from '@/types';
import { DotsHorizontal, ErrorIcon } from '../icons';
import { InlineIconButton } from '../InlineIconButton';
import { LocalModal } from '../Modal';

export type OptionsMenuItem = {
  confirmContent?: ReactNode;
  confirmIcon?: ReactNode;
  content: string;
  icon?: ReactNode;
  modalContent?: ReactNode;
  onClick?: (event: MouseEvent) => void;
  shouldConfirm?: boolean;
  isHidden?: boolean;
};

type OptionsMenuProps = {
  items: OptionsMenuItem[];
  filterItem?: (filter: string, item: OptionsMenuItem) => boolean;
  placement?: TippyProps['placement'];
  maxHeight?: CSSProperties['maxHeight'];
  icon?: ReactNode;
};

export const OptionsMenu = ({
  items,
  filterItem,
  placement = 'auto',
  maxHeight,
  icon,
}: OptionsMenuProps) => {
  const [filter, setFilter] = useState('');
  const [visible, setVisible] = useState(false);
  const [modalContent, setModalContent] = useState<Nullable<ReactNode>>(
    undefined,
  );
  const [confirmIndex, setConfirmIndex] = useState<Nullable<number>>(undefined);
  const filterInputRef = useRef<HTMLInputElement>(null);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const resetFilter = () => filter !== '' && setFilter('');

  const hasFilterFn = !!filterItem;

  const [filteredItems, hasFilteredItems] = useMemo(() => {
    const filtered = items.filter((item) => {
      if (item.isHidden) {
        return false;
      }
      if (filter && filterItem && !filterItem(filter, item)) {
        return false;
      }
      return true;
    });

    return [filtered, filtered.length > 0];
  }, [filter, filterItem, items]);

  const modalCtx = useMemo(() => {
    return {
      close: () => setModalContent(undefined),
    };
  }, [setModalContent]);

  // Focus on filter field if visible and has filter fn
  useEffect(() => {
    if (visible && hasFilterFn && filterInputRef.current) {
      filterInputRef.current.focus();
    }
  }, [hasFilterFn, visible]);

  useEffect(() => {
    if (!visible && confirmIndex) {
      setConfirmIndex(undefined);
    }
  }, [visible, confirmIndex]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setConfirmIndex(undefined);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [confirmIndex]);

  return (
    <>
      <LocalModal
        isOpen={!!modalContent}
        content={modalContent}
        ctx={modalCtx}
      />

      <Tippy
        interactive
        appendTo={() => document.body}
        visible={visible}
        onClickOutside={hide}
        placement={placement}
        onHidden={resetFilter}
        content={
          <div>
            {filterItem && (
              <input
                type="search"
                className="mt-1 mb-2 focus:outline-none focus:ring block w-full sm:text-sm border-gray-300 rounded-md text-black"
                onChange={(event) => setFilter(event.target.value)}
                value={filter}
                placeholder="Filter..."
                ref={filterInputRef}
              />
            )}
            <div style={{ maxHeight }} className="overflow-auto">
              {filteredItems.map((item, index) => {
                const isConfirming = confirmIndex === index;
                const shouldConfirm = !!item.shouldConfirm;

                const icon = isConfirming
                  ? item.confirmIcon || <ErrorIcon />
                  : item.icon;

                const content =
                  (isConfirming && (item.confirmContent || 'Are you sure?')) ||
                  item.content;

                return (
                  <button
                    key={index}
                    type="button"
                    className="flex items-center w-full py-1 text-left hover:text-gray-300"
                    onClick={(event) => {
                      if (confirmIndex && !isConfirming) {
                        setConfirmIndex(undefined);
                      }

                      if ('modalContent' in item) {
                        setModalContent(item.modalContent);
                        hide();
                      } else if (modalContent) {
                        setModalContent(undefined);
                      }

                      if ('onClick' in item && item.onClick) {
                        if (shouldConfirm && !isConfirming) {
                          setConfirmIndex(index);
                        } else {
                          item.onClick(event);
                          hide();
                        }
                      }
                    }}
                  >
                    {icon && <span className="pr-1">{icon}</span>}
                    <span>{content}</span>
                  </button>
                );
              })}

              {!hasFilteredItems && (
                <div className="w-full py-1">No options...</div>
              )}
            </div>
          </div>
        }
      >
        <InlineIconButton type="button" onClick={visible ? hide : show}>
          {icon || <DotsHorizontal />}
        </InlineIconButton>
      </Tippy>
    </>
  );
};
