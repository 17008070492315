import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { gql, useQuery } from 'urql';

import { BooleanValue } from '@/bits';
import { Card, CardBody } from '@/components';
import { useTranslate } from '@/contexts';
import {
  PlayerMarketingBlockQuery,
  PlayerMarketingBlockQueryVariables,
} from './__generated__/PlayerMarketingBlockQuery';
import { PlayerMarketingBlockStaticQuery } from './__generated__/PlayerMarketingBlockStaticQuery';

const BLOCK_STATIC_QUERY = graphql`
  query PlayerMarketingBlockStaticQuery {
    sanityPlayerMarketingBlock {
      title {
        ...LocaleString
      }
      acceptsEmailMarketing {
        ...LocaleString
      }
      acceptsSmsMarketing {
        ...LocaleString
      }
    }
  }
`;

const query = gql`
  query PlayerMarketingBlockQuery($playerId: ID!) {
    player(playerId: $playerId) {
      id
      isEmailMarketingAccepted
      isSmsMarketingAccepted
    }
  }
`;

const PlayerMarketingBlock = () => {
  const params = useParams();
  const { t } = useTranslate();
  const block = useStaticQuery<PlayerMarketingBlockStaticQuery>(
    BLOCK_STATIC_QUERY,
  ).sanityPlayerMarketingBlock;

  const [{ data, fetching }] = useQuery<
    PlayerMarketingBlockQuery,
    PlayerMarketingBlockQueryVariables
  >({
    query,
    variables: {
      playerId: params.playerId,
    },
  });

  if (!block) {
    return null;
  }

  return (
    <Card size="md" title={t(block.title)}>
      <CardBody>
        <div className="p-3 grid grid-cols-1 lg:grid-cols-2 gap-2">
          <BooleanValue
            title={t(block.acceptsEmailMarketing)}
            fetching={fetching}
            value={data?.player.isEmailMarketingAccepted}
          />
          <BooleanValue
            title={t(block.acceptsSmsMarketing)}
            fetching={fetching}
            value={data?.player.isSmsMarketingAccepted}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerMarketingBlock;
