import countryList from 'country-list';
import React from 'react';

import { BaseInputFieldProps } from './BaseInputField';
import { SelectField } from './SelectField';

const options = countryList.getData().map(({ code, name }) => ({
  value: code,
  label: name,
}));

export const CountryField = (props: BaseInputFieldProps) => {
  return <SelectField options={options} {...props} />;
};
