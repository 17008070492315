import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  formatISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';

import { GranularityEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import { PlayerAnalytics_player_stats_rows } from './__generated__/PlayerAnalytics';
import { ViewerAnalytics_viewer_stats_rows } from './__generated__/ViewerAnalytics';
import { TimeRangeEnum } from './queryParams';

export type StatsRow =
  | PlayerAnalytics_player_stats_rows
  | ViewerAnalytics_viewer_stats_rows;

export type GameDescriptorEntry = StatsRow['gameDescriptor'];
export type PlayerEntry = Nullable<ViewerAnalytics_viewer_stats_rows['player']>;

type AnalyticsParamOptions = {
  key: 'rows' | 'columns' | 'values';
  value: string;
};

export type AddAnalyticsParamFn = (options: AnalyticsParamOptions) => void;
export type RemoveAnalyticsParamFn = (options: AnalyticsParamOptions) => void;
export type SetTimeRange = (newRange: TimeRangeEnum | string) => void;
export type SetStartOrEndDate = (newDate: Date) => void;

export type SwitchAnalyticsParamsPositionsFn = (options: {
  key: AnalyticsParamOptions['key'];
  fromIndex: number;
  toIndex: number;
}) => void;

const mapTimePeriod = (granularity: GranularityEnum) => ({
  granularity,
  timePeriod: true,
});

export const mapTimeRangeToVariables = (identifier: string) => {
  switch (identifier) {
    case 'all':
      return {
        granularity: GranularityEnum.All,
      };
    case 'day':
      return mapTimePeriod(GranularityEnum.Day);
    case 'hour':
      return mapTimePeriod(GranularityEnum.Hour);
    case 'minute':
      return mapTimePeriod(GranularityEnum.Minute);
    case 'month':
      return mapTimePeriod(GranularityEnum.Month);
    case 'quarter':
      return mapTimePeriod(GranularityEnum.Quarter);
    case 'second':
      return mapTimePeriod(GranularityEnum.Second);
    case 'week':
      return mapTimePeriod(GranularityEnum.Week);
    case 'year':
      return mapTimePeriod(GranularityEnum.Year);
    default: {
      console.error(`Could not map time range ${identifier}`);
      return {};
    }
  }
};

const variablesFromDates = (
  range: TimeRangeEnum | string,
  startDate?: Nullable<Date>,
  endDate?: Nullable<Date>,
) => {
  switch (range) {
    case TimeRangeEnum.LastMonth: {
      const d = subMonths(new Date(), 1);
      return {
        from: startOfMonth(d),
        to: endOfMonth(d),
      };
    }
    case TimeRangeEnum.ThisMonth:
      return {
        from: startOfMonth(new Date()),
        to: endOfMonth(new Date()),
      };
    case TimeRangeEnum.LastWeek: {
      const d = subWeeks(new Date(), 1);
      return {
        from: startOfWeek(d, { weekStartsOn: 1 }),
        to: endOfWeek(d, { weekStartsOn: 1 }),
      };
    }
    case TimeRangeEnum.ThisWeek:
      return {
        from: startOfWeek(new Date(), { weekStartsOn: 1 }),
        to: endOfWeek(new Date(), { weekStartsOn: 1 }),
      };
    case TimeRangeEnum.Yesterday: {
      const d = subDays(new Date(), 1);
      return {
        from: startOfDay(d),
        to: endOfDay(d),
      };
    }
    case TimeRangeEnum.Today:
      return {
        from: startOfDay(new Date()),
        to: endOfDay(new Date()),
      };
    case TimeRangeEnum.Custom:
      return {
        from: startDate || startOfDay(new Date()),
        to: endDate || endOfDay(new Date()),
      };
    default: {
      const d = new Date();
      d.setFullYear(Number(range));
      return {
        from: startOfYear(d),
        to: endOfYear(d),
      };
    }
  }
};

export const stringVariablesFromDates = (
  range: TimeRangeEnum | string,
  startDate?: Nullable<Date>,
  endDate?: Nullable<Date>,
) => {
  const { from, to } = variablesFromDates(range, startDate, endDate);

  return {
    from: formatISO(from),
    to: formatISO(to),
  };
};
