import GraphiQL from 'graphiql';
import { Fetcher } from 'graphiql';
import React, { useCallback } from 'react';
import { useClient } from 'urql';

import { makeAuthClient } from '@/bootstrap/okta';
import { Secure } from '@/components';
import 'graphiql/graphiql.min.css';

const createStorage = (namespace: string): Storage | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  const storage = window.localStorage;
  return {
    clear: storage.clear,
    key: storage.key,
    length: storage.length,
    removeItem: (key: string) => storage.removeItem(`${namespace}.${key}`),
    getItem: (key: string) => storage.getItem(`${namespace}.${key}`),
    setItem: (key: string, value: string) =>
      storage.setItem(`${namespace}.${key}`, value),
  };
};

const storage = createStorage('bo-graphql');

const GraphQLEditorBlock = () => {
  const client = useClient();
  const authService = makeAuthClient();

  const fetcher: Fetcher = useCallback(
    async (graphQLParams) => {
      const { accessToken } = await authService.tokenManager.getTokens();

      const result = await fetch(client.url, {
        ...client.fetchOptions,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken?.accessToken || ''}`,
        },
        body: JSON.stringify(graphQLParams),
      }).then((response) => response.json());

      return {
        data: result.data,
        errors: result.errors,
      };
    },
    [client, authService],
  );

  if (typeof window === 'undefined') {
    return null;
  }

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div style={{ minHeight: '100vh', height: '100vh' }}>
      <GraphiQL fetcher={fetcher} storage={storage} />
    </div>
  );
};

const Wrapper = () => (
  <Secure>
    <GraphQLEditorBlock />
  </Secure>
);

export default Wrapper;
