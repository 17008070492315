import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { useGetPlayerOverviewPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  DetailList,
  InlineIconButton,
} from '@/components';
import { CountryFlag } from '@/components/CountryFlag';
import { useDataGrid } from '@/components/DataGrid';
import {
  DocumentDownloadIcon,
  InformationIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { AccountStatusEnum, BrandEnum, PlayerSearchOrder } from '@/globalTypes';
import formatDate from '@/utils/formatter/formatDate';
import { SanityPlayerSearchResultBlockFragment } from './__generated__/SanityPlayerSearchResultBlockFragment';
import usePlayerSearchReport from './usePlayerSearchReport';
import usePlayerSearchResults from './usePlayerSearchResults';

export const Fragment = graphql`
  fragment SanityPlayerSearchResultBlockFragment on SanityPlayerSearchResultBlock {
    title {
      ...LocaleString
    }
    country {
      ...LocaleString
    }
    brand {
      ...LocaleString
    }
    firstName {
      ...LocaleString
    }
    lastName {
      ...LocaleString
    }
    email {
      ...LocaleString
    }
    status {
      ...LocaleString
    }
    registrationDate {
      ...LocaleString
    }
    ssn {
      ...LocaleString
    }
    street {
      ...LocaleString
    }
    city {
      ...LocaleString
    }
    zipCode {
      ...LocaleString
    }
    playerId {
      ...LocaleString
    }
    registrationIp {
      ...LocaleString
    }
    registrationIpCountry {
      ...LocaleString
    }
    birthDate {
      ...LocaleString
    }
    lastLoginAt {
      ...LocaleString
    }
    lastLoginIp {
      ...LocaleString
    }
    phoneNumber {
      ...LocaleString
    }
    balance {
      ...LocaleString
    }
    selfExclusion {
      ...LocaleString
    }
  }
`;

const PlayerSearchResultBlock: FC<{
  block: SanityPlayerSearchResultBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();

  const getPlayerOverviewLink = useGetPlayerOverviewPageLink();

  const {
    playerSearchItems,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    fetching,
    defaultFilter,
    updateFilter,
  } = usePlayerSearchResults();
  const report = usePlayerSearchReport();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerSearchResult',
    data: playerSearchItems,
    defaultFilter,
    updateFilter,
    desc,
    fetching,
    setOrderBy,
    orderBy,
    getRowLink: ({ row }) => getPlayerOverviewLink(row.playerId),
    schema: (s) => [
      s.countryValue({
        field: 'countryCode',
        title: t(block.country),
        filterField: 'countryCode',
        orderBy: PlayerSearchOrder.countryCode,
      }),
      s.enumValue({
        field: 'brand',
        title: t(block.brand),
        filterField: 'brand',
        e: BrandEnum,
        orderBy: PlayerSearchOrder.brand,
        getValue: ({ row }) => row.brand?.name,
      }),
      s.stringValue({
        field: 'firstName',
        title: t(block.firstName),
        filterField: 'firstName',
        orderBy: PlayerSearchOrder.firstName,
      }),
      s.stringValue({
        field: 'lastName',
        title: t(block.lastName),
        filterField: 'lastName',
        orderBy: PlayerSearchOrder.lastName,
      }),
      s.stringValue({
        field: 'email',
        title: t(block.email),
        filterField: 'email',
        getValue: ({ row }) => row.emailInfo?.email,
      }),
      s.enumValue({
        field: 'accountStatus',
        title: t(block.status),
        filterField: 'accountStatus',
        e: AccountStatusEnum,
      }),
      s.dateTimeValue({
        field: 'registeredAt',
        title: t(block.registrationDate),
        orderBy: PlayerSearchOrder.registeredAt,
        getValue: ({ row }) => row.registrationInfoV2?.registeredAt,
      }),
      s.dateTimeValue({
        field: 'birthDate',
        title: t(block.birthDate),
        orderBy: PlayerSearchOrder.dateOfBirth,
      }),
      s.stringValue({
        field: 'ssn',
        title: t(block.ssn),
        filterField: 'ssn',
      }),
      s.stringValue({
        field: 'street',
        title: t(block.street),
        filterField: 'street',
        getValue: ({ row }) => row.addressInfo?.street,
      }),
      s.stringValue({
        field: 'city',
        title: t(block.city),
        filterField: 'city',
        orderBy: PlayerSearchOrder.city,
        getValue: ({ row }) => row.addressInfo?.city,
      }),
      s.stringValue({
        field: 'zipCode',
        title: t(block.zipCode),
        filterField: 'zipCode',
        orderBy: PlayerSearchOrder.zipCode,
        getValue: ({ row }) => row.addressInfo?.zipCode,
      }),
      s.stringValue({
        field: 'rawPlayerId',
        title: t(block.playerId),
        filterField: 'id',
        getValue: ({ row }) => row.rawPlayerId,
      }),
      s.stringValue({
        field: 'registeredIp',
        title: t(block.registrationIp),
        filterField: 'registeredIp',
        getValue: ({ row }) => row.registrationInfoV2?.registrationIp,
      }),
      s.countryValue({
        field: 'registeredIpCountryCode',
        title: t(block.registrationIpCountry),
        getValue: ({ row }) =>
          row.registrationInfoV2?.registrationIpCountryCode,
      }),
      s.dateTimeValue({
        field: 'lastLoginAt',
        title: t(block.lastLoginAt),
        fromFilterField: 'lastLoginFrom',
        toFilterField: 'lastLoginTo',
        getValue: ({ row }) => row.loginInfo?.lastLoginAt,
      }),
      s.stringValue({
        field: 'lastLoginIp',
        title: t(block.lastLoginIp),
        filterField: 'lastLoginIp',
        getValue: ({ row }) => row.loginInfo?.lastLoginIp,
        wrap: ({ row }) => (
          <>
            <CountryFlag countryCode={row.loginInfo?.lastLoginIpCountryCode} />{' '}
            {row.loginInfo?.lastLoginIp}
          </>
        ),
      }),
      s.phoneNumberValue({
        field: 'phoneNumber',
        title: t(block.phoneNumber),
        filterField: 'phoneNumber',
        orderBy: PlayerSearchOrder.phoneNumber,
      }),
      s.monetaryValue({
        field: 'balance',
        title: t(block.balance),
        fromFilterField: 'minBalance',
        toFilterField: 'maxBalance',
        orderBy: PlayerSearchOrder.balance,
      }),
      s.stringValue({
        field: 'selfExclusionDetails',
        title: 'Self Exclusion',
        getValue: ({ row }) => {
          const details = row.player.selfExclusionDetails;
          const pending = details?.pending;

          if (!details) {
            return '-';
          }

          const descriptions = [
            details.exclusionEndsAt && 'Active',
            pending && 'Pending',
          ];

          return (
            <div>
              {descriptions.filter(Boolean).join(', ')}
              <ControlledModal
                content={
                  <Card
                    size="lg"
                    title="Player self exclusion"
                    options={
                      <CardOptions>
                        <CardCloseButton />
                      </CardOptions>
                    }
                  >
                    <DetailList
                      items={[
                        {
                          label: 'Ends at',
                          value: formatDate(details.exclusionEndsAt),
                        },
                        {
                          label: 'Will be cancelled at',
                          value: formatDate(details.willBeCancelledAt),
                        },
                        {
                          label: 'Pending configured at',
                          value:
                            (pending && formatDate(pending.configuredAt)) ||
                            '-',
                        },
                        {
                          label: 'Pending from',
                          value:
                            (pending && formatDate(pending.activeFrom)) || '-',
                        },
                        {
                          label: 'Pending ends at',
                          value:
                            (pending && formatDate(pending.exclusionEndsAt)) ||
                            '-',
                        },
                      ]}
                    />
                  </Card>
                }
              >
                <InlineIconButton>
                  <InformationIcon />
                </InlineIconButton>
              </ControlledModal>
            </div>
          );
        },
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <CardOptionsButton onClick={() => report.generate()}>
            <DocumentDownloadIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerSearchResultBlock;
