import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import React, { FC } from 'react';

import { useGetPlayerTransactionPageLink } from '@/bits/links/useLink';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  DocumentDownloadIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  GameProvider,
  PaymentStatus,
  TransactionSearchOrder,
  TransactionType,
} from '@/globalTypes';
import { assert } from '@/utils/error';
import { SanityPlayerTransactionsBlockFragment } from './__generated__/SanityPlayerTransactionsBlockFragment';
import usePlayerTransactions from './usePlayerTransactions';
import usePlayerTransactionsReport from './usePlayerTransactionsReport';

export const Fragment = graphql`
  fragment SanityPlayerTransactionsBlockFragment on SanityPlayerTransactionsBlock {
    title {
      ...LocaleString
    }
  }
`;

const PlayerTransactionsBlock: FC<{
  block: SanityPlayerTransactionsBlockFragment;
}> = ({ block }) => {
  const params = useParams();

  assert(params.playerId, 'missing playerId');

  const {
    transactions,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerTransactions(params.playerId);
  const report = usePlayerTransactionsReport(params.playerId);

  const transactionPageLink = useGetPlayerTransactionPageLink()(
    params.playerId,
  );

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerTransactions',
    data: transactions,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    getRowLink: ({ row }) =>
      `${transactionPageLink}?${queryString.stringify({
        rawTransactionId: row.rawTransactionId,
      })}`,
    schema: (s) => [
      s.dateTimeValue({
        field: 'processedAt',
        title: 'Time',
        fromFilterField: 'from',
        toFilterField: 'to',
        orderBy: TransactionSearchOrder.processedAt,
      }),
      s.enumValue({
        field: 'transactionType',
        title: 'Type',
        e: TransactionType,
        filterField: 'transactionTypes',
        isMulti: true,
      }),
      s.enumValue({
        field: 'paymentStatus',
        title: 'Payment Status',
        e: PaymentStatus,
        filterField: 'paymentStatus',
        isMulti: true,
      }),
      s.gameValue({
        field: 'gameDescriptor',
        title: 'Game',
        orderBy: TransactionSearchOrder.gameId,
      }),
      s.enumValue({
        field: 'gameProvider',
        title: 'Game Provider',
        e: GameProvider,
      }),
      s.monetaryValue({
        field: 'amount',
        title: 'Amount',
        fromFilterField: 'minAmount',
        toFilterField: 'maxAmount',
        orderBy: TransactionSearchOrder.amount,
      }),
      s.monetaryValue({
        field: 'openingBalance',
        title: 'Opening Balance',
      }),
      s.monetaryValue({
        field: 'closingBalance',
        title: 'Closing Balance',
      }),
      s.booleanValue({
        field: 'isRoundFinished',
        title: 'Round Finished?',
      }),
      s.stringValue({
        field: 'rawTransactionId',
        title: 'Transaction ID',
        filterField: 'rawId',
      }),
      s.stringValue({
        field: 'extTransactionId',
        title: 'Ext. ID',
      }),
      s.stringValue({
        field: 'gameSessionId',
        title: 'Game Session ID',
      }),
      s.stringValue({
        field: 'gameRoundId',
        title: 'Game Round ID',
        filterField: 'gameRoundId',
      }),
      s.stringValue({
        field: 'paymentMethod',
        title: 'Payment Method',
      }),
      s.stringValue({
        field: 'comment',
        title: 'Comment',
      }),
      s.stringValue({
        field: 'payload',
        title: 'Payload',
        hidden: true,
      }),
    ],
  });
  const { t } = useTranslate();

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <CardOptionsButton onClick={() => report.generate()}>
            <DocumentDownloadIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerTransactionsBlock;
