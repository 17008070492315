import { useEffect, useMemo, useState } from 'react';
import useFetch from 'use-http';

// @ts-expect-error
import SearchWorker from '@/workers/search.worker.js';

let worker: SearchWorker | null = null;

export default function useSearchWorker() {
  const [loadingWorker, setLoadingWorker] = useState(true);

  const searchWorker = useMemo(() => {
    if (worker) {
      return worker;
    }
    return (worker = typeof window === 'object' && new SearchWorker());
  }, []);

  const { loading, data } = useFetch('/games.json', {}, []);

  useEffect(() => {
    if (!loading) {
      searchWorker.updateInventory(data).then(() => setLoadingWorker(false));
    }
  }, [data, loading, searchWorker]);

  return {
    searchWorker: loading || loadingWorker ? null : searchWorker,
    rawGames: loading ? null : data,
    loading: loading || loadingWorker,
  };
}
