import { useParams } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { gql, useQuery } from 'urql';

import { BooleanValue } from '@/bits';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Value,
} from '@/components';
import { EditIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerAmlRiskLevelForm,
  PlayerRequestDocumentsForm,
  PlayerVerifySourceOfWealthForm,
} from '@/forms';
import formatMoney from '@/utils/formatter/formatMoney';
import { fundingOptionLabels } from '@/utils/funding-options';
import {
  PlayerSowBlockQuery,
  PlayerSowBlockQueryVariables,
} from './__generated__/PlayerSowBlockQuery';
import { SanityPlayerSowBlockStaticQuery } from './__generated__/SanityPlayerSowBlockStaticQuery';

const BLOCK_STATIC_QUERY = graphql`
  query SanityPlayerSowBlockStaticQuery {
    sanityPlayerSowBlock {
      title {
        ...LocaleString
      }
      risk {
        ...LocaleString
      }
      status {
        ...LocaleString
      }
      needsManualApproval {
        ...LocaleString
      }
      nationality {
        ...LocaleString
      }
      fundingOptions {
        ...LocaleString
      }
      salaryLowerBound {
        ...LocaleString
      }
      salaryUpperBound {
        ...LocaleString
      }
      expendingLowerBound {
        ...LocaleString
      }
      expendingUpperBound {
        ...LocaleString
      }
      otherFundingDescription {
        ...LocaleString
      }
      requiredDocumentTypes {
        ...LocaleString
      }
    }
  }
`;

const QUERY = gql`
  query PlayerSowBlockQuery($playerId: ID!) {
    player(playerId: $playerId) {
      id
      rawPlayerId
      playerComplianceDetails {
        id
        needsManualReview
        requiredDocumentTypes
        risk
        status
      }
      sourceOfWealth {
        expendingLowerBound
        expendingUpperBound
        fundingOptions
        nationality
        otherFundingDescription
        salaryLowerBound
        salaryUpperBound
      }
    }
  }
`;

const PlayerSowBlock = () => {
  const params = useParams();
  const { t } = useTranslate();
  const block = useStaticQuery<SanityPlayerSowBlockStaticQuery>(
    BLOCK_STATIC_QUERY,
  ).sanityPlayerSowBlock;

  const [{ data, fetching }, refresh] = useQuery<
    PlayerSowBlockQuery,
    PlayerSowBlockQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  if (!block) {
    return null;
  }

  const details = data?.player.playerComplianceDetails;
  const sow = data?.player.sourceOfWealth;

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          <Value
            title={t(block.risk)}
            fetching={fetching}
            value={details?.risk}
            suffix={
              details && (
                <ControlledModal
                  content={
                    params.playerId && (
                      <PlayerAmlRiskLevelForm playerId={params.playerId} />
                    )
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
          <Value
            title={t(block.status)}
            fetching={fetching}
            value={details?.status}
            suffix={
              details && (
                <ControlledModal
                  content={
                    params.playerId && (
                      <PlayerVerifySourceOfWealthForm
                        playerId={params.playerId}
                      />
                    )
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
          <BooleanValue
            title={t(block.needsManualApproval)}
            fetching={fetching}
            value={details?.needsManualReview}
          />
          <Value
            title={t(block.nationality)}
            fetching={fetching}
            value={sow?.nationality}
          />
          <Value
            title={t(block.fundingOptions)}
            fetching={fetching}
            value={sow?.fundingOptions.join(', ')}
          />
          <Value
            title={t(block.salaryLowerBound)}
            fetching={fetching}
            value={formatMoney(sow?.salaryLowerBound)}
          />
          <Value
            title={t(block.salaryUpperBound)}
            fetching={fetching}
            value={formatMoney(sow?.salaryUpperBound)}
          />
          <Value
            title={t(block.expendingLowerBound)}
            fetching={fetching}
            value={formatMoney(sow?.expendingLowerBound)}
          />
          <Value
            title={t(block.expendingUpperBound)}
            fetching={fetching}
            value={formatMoney(sow?.expendingUpperBound)}
          />
          <Value
            title={t(block.otherFundingDescription)}
            fetching={fetching}
            value={sow?.otherFundingDescription}
          />
          <Value
            title={t(block.requiredDocumentTypes)}
            fetching={fetching}
            value={details?.requiredDocumentTypes
              .map((value) => fundingOptionLabels[value])
              .join(', ')}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerRequestDocumentsForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerSowBlock;
