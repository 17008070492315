import { useParams } from '@reach/router';
import { Link, graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useCallback } from 'react';
import { useQuery } from 'urql';

import { useGetPlayerOverviewPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CardOptionsButton,
  ConditionalWrap,
  ControlledModal,
  InlineIconButton,
  Table,
  Value,
} from '@/components';
import {
  EditIcon,
  ExternalLinkIcon,
  EyeIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerBirthDateForm,
  PlayerCityForm,
  PlayerNameForm,
  PlayerStreetForm,
  PlayerZipCodeForm,
} from '@/forms';
import formatCountryCode from '@/utils/formatter/formatCountryCode';
import {
  PlayerKycOverviewBox,
  PlayerKycOverviewBoxVariables,
  PlayerKycOverviewBox_player_kycCheckStatus,
} from './__generated__/PlayerKycOverviewBox';
import { SanityPlayerKycOverviewBlockFragment } from './__generated__/SanityPlayerKycOverviewBlockFragment';

export const Fragment = graphql`
  fragment SanityPlayerKycOverviewBlockFragment on SanityPlayerKycOverviewBlock {
    title {
      ...LocaleString
    }
    firstNameLabel {
      ...LocaleString
    }
    lastNameLabel {
      ...LocaleString
    }
    titleLabel {
      ...LocaleString
    }
    ssnLabel {
      ...LocaleString
    }
    birthDateLabel {
      ...LocaleString
    }
    countryLabel {
      ...LocaleString
    }
    zipCodeLabel {
      ...LocaleString
    }
    streetLabel {
      ...LocaleString
    }
    cityLabel {
      ...LocaleString
    }
    genderLabel {
      ...LocaleString
    }
    upToDateVerificationsLabel {
      ...LocaleString
    }
    kycStatusLabel {
      ...LocaleString
    }
    kycStatusWillBeRequired {
      ...LocaleString
    }
    kycStatusRequired {
      ...LocaleString
    }
    kycStatusPending {
      ...LocaleString
    }
    kycStatusFailed {
      ...LocaleString
    }
    kycStatusRejected {
      ...LocaleString
    }
    kycStatusDone {
      ...LocaleString
    }
    outdatedVerificationsLabel {
      ...LocaleString
    }
    sharedSowAccountsLabel {
      ...LocaleString
    }
    expiredVerificationsLabel {
      ...LocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerKycOverviewBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      firstName
      lastName
      title
      ssn
      birthDate
      countryCode
      zipCode
      street
      city
      gender
      verificationTypes {
        upToDateVerifications {
          verificationType
        }
        expiredVerifications
      }
      kycCheckStatus {
        status
        kycFlow {
          status
        }
        sumSubEndUserId
      }
      playersLinkedBySourceOfWealth {
        id
        player {
          id
          uuid
          brand {
            name
          }
        }
      }
    }
  }
`;

const useGetKycStatus = (block: SanityPlayerKycOverviewBlockFragment) => {
  const { t } = useTranslate();
  const getKycStatus = useCallback<
    (
      kycCheckStatus: PlayerKycOverviewBox_player_kycCheckStatus,
    ) => string | undefined
  >(
    (kycCheckStatus) => {
      const status =
        kycCheckStatus?.kycFlow?.status &&
        ['Failed', 'Rejected', 'Pending'].includes(
          kycCheckStatus.kycFlow.status,
        ) &&
        kycCheckStatus.status !== 'Done'
          ? kycCheckStatus.kycFlow.status
          : kycCheckStatus.status;

      switch (status) {
        case 'WillBeRequired':
          return t(block.kycStatusWillBeRequired);
        case 'Required':
          return t(block.kycStatusRequired);
        case 'Pending':
          return t(block.kycStatusPending);
        case 'Failed':
          return t(block.kycStatusFailed);
        case 'Rejected':
          return t(block.kycStatusRejected);
        case 'Done':
          return t(block.kycStatusDone);
        default: {
          console.warn(`No translation for kyc status ${status}`);
          return undefined;
        }
      }
    },
    [block, t],
  );
  return getKycStatus;
};

const PlayerKycOverviewBlock: FC<{
  block: SanityPlayerKycOverviewBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();
  const getPlayerOverviewPageLink = useGetPlayerOverviewPageLink();
  const getKycStatus = useGetKycStatus(block);

  const [{ data, fetching }, refresh] = useQuery<
    PlayerKycOverviewBox,
    PlayerKycOverviewBoxVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          <Value
            fetching={fetching}
            title={t(block.firstNameLabel)}
            value={data?.player.firstName}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerNameForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.lastNameLabel)}
            value={data?.player.lastName}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerNameForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.birthDateLabel)}
            value={data?.player.birthDate}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerBirthDateForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.genderLabel)}
            value={data?.player.gender}
          />

          <Value
            fetching={fetching}
            title={t(block.ssnLabel)}
            value={data?.player.ssn}
          />
          <Value
            fetching={fetching}
            title={t(block.countryLabel)}
            value={formatCountryCode(data?.player.countryCode)}
          />
          <Value
            fetching={fetching}
            title={t(block.streetLabel)}
            value={data?.player.street}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerStreetForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.zipCodeLabel)}
            value={data?.player.zipCode}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerZipCodeForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.cityLabel)}
            value={data?.player.city}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerCityForm playerId={data.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          />
          <Value
            fetching={fetching}
            title={t(block.upToDateVerificationsLabel)}
            value={data?.player.verificationTypes?.upToDateVerifications
              .map((a) => a.verificationType)
              .join(', ')}
          />
          <Value
            fetching={fetching}
            title={t(block.kycStatusLabel)}
            value={
              data?.player.kycCheckStatus &&
              getKycStatus(data.player.kycCheckStatus)
            }
            suffix={
              data?.player.kycCheckStatus?.sumSubEndUserId && (
                <InlineIconButton>
                  <a
                    href={`${process.env.SUMSUB_APPLICANT_PROFILE}externalUserId=${data.player.kycCheckStatus.sumSubEndUserId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExternalLinkIcon />
                  </a>
                </InlineIconButton>
              )
            }
          />
          <Value
            fetching={fetching}
            title={t(block.outdatedVerificationsLabel)}
            value={data?.player.verificationTypes?.expiredVerifications.join(
              ', ',
            )}
          />
          <Value
            fetching={fetching}
            title={t(block.sharedSowAccountsLabel)}
            value={`${data?.player.playersLinkedBySourceOfWealth?.length ?? 0}`}
            suffix={
              data?.player.playersLinkedBySourceOfWealth?.length != null && (
                <ControlledModal
                  content={
                    <Card
                      size="lg"
                      title={t(block.sharedSowAccountsLabel)}
                      options={
                        <CardOptions>
                          <CardCloseButton />
                        </CardOptions>
                      }
                    >
                      <Table
                        headings={['Player', 'Brand']}
                        rows={data.player.playersLinkedBySourceOfWealth.map(
                          (link) => {
                            const pageLink = getPlayerOverviewPageLink(
                              link.player.id,
                            );
                            return {
                              key: link.id,
                              data: [
                                <ConditionalWrap
                                  condition={!!pageLink}
                                  wrap={(a) =>
                                    pageLink ? (
                                      <Link to={pageLink}>{a}</Link>
                                    ) : null
                                  }
                                >
                                  {link.player.uuid}
                                </ConditionalWrap>,
                                link.player.brand.name,
                              ],
                            };
                          },
                        )}
                      />
                    </Card>
                  }
                >
                  <InlineIconButton>
                    <EyeIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerKycOverviewBlock;
