import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useQuery } from 'urql';

import {
  PlayersByIds,
  PlayersByIdsVariables,
  PlayersByIds_viewer_playersSearchV2_edges,
  PlayersByIds_viewer_playersSearchV2_edges_node,
} from './__generated__/PlayersByIds';

const PLAYERS_BY_IDS_QUERY = gql`
  query PlayersByIds($playerIds: [String!]!) {
    viewer {
      id
      playersSearchV2(playerIds: $playerIds) {
        edges {
          node {
            uuid
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export function usePlayersByIds() {
  const [playerIds, setPlayerIds] = useState<Array<string>>([]);
  const [players, setPlayers] =
    useState<(PlayersByIds_viewer_playersSearchV2_edges_node | undefined)[]>();
  const [{ data, fetching }, refresh] = useQuery<
    PlayersByIds,
    PlayersByIdsVariables
  >({
    query: PLAYERS_BY_IDS_QUERY,
    pause: !playerIds.length,
    variables: {
      playerIds,
    },
  });

  useEffect(() => {
    const response = data?.viewer.playersSearchV2?.edges;
    const players = response?.map(
      (value: PlayersByIds_viewer_playersSearchV2_edges | null) => value?.node,
    );
    setPlayers(players);
  }, [data, playerIds, setPlayers, fetching]);

  return {
    refresh,
    fetching,
    players,
    setPlayerIds,
  };
}
