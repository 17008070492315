import { useLocation } from '@reach/router';
import { useCallback } from 'react';

import { useViewer } from '@/contexts/ViewerContext';
import { OrgEnum } from '@/globalTypes';
import { Nullable } from '@/types';

export type Operator = {
  name: string;
  path: string;
  id: OrgEnum;
  graphQLEndpoint: string;
};

export const allOperators: Operator[] = [
  {
    name: 'Infiniza',
    path: 'infiniza',
    id: OrgEnum.IN,
    graphQLEndpoint: process.env.GATSBY_INFINIZA_API_URL!,
  },
  {
    name: 'Tekzia',
    path: 'tekzia',
    id: OrgEnum.MT,
    graphQLEndpoint: process.env.GATSBY_TEKZIA_API_URL!,
  },
];

export const operatorsById = allOperators.reduce((acc, value) => {
  acc[value.id] = value;
  return acc;
}, {} as Record<OrgEnum, Operator>);

export const getOperatorFromPath = (path: string) => {
  const [orgPath] = path.slice(1).split('/');

  return allOperators.find((a) => a.path === orgPath);
};

export const useOperators = () => {
  const { viewer } = useViewer();

  return allOperators.filter(
    (a) => viewer?.identity.orgs.includes(a.id) || false,
  );
};

export const useCurrentOperator = () => {
  const location = useLocation();
  return getOperatorFromPath(location.pathname);
};

export const useGetOperatorPath = () => {
  const location = useLocation();

  return useCallback(
    (path?: Nullable<string>) => {
      if (!path) {
        return path;
      }

      const org = getOperatorFromPath(location.pathname);

      return `/${org ? org.path : ''}${path}`;
    },
    [location.pathname],
  );
};

export const useOperatorPath = (path?: Nullable<string>) => {
  return useGetOperatorPath()(path);
};
