import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import { last, sum } from 'lodash/fp';
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'urql';

import { Card, CardBody, Copy, Tippy, Value } from '@/components';
import { useTime, useTranslate, useViewer } from '@/contexts';
import { predefinedTimeFrames } from '@/contexts/TimeContext/predefinedTimeFrames';
import { mapVariables } from '@/utils';
import formatMoney from '@/utils/formatter/formatMoney';
import { withKeyByPlayerIdParam } from '@/utils/withKeyByParam';
import { KpiBox, KpiBoxVariables } from './__generated__/KpiBox';
import { SanityKpiBlockFragment } from './__generated__/SanityKpiBlockFragment';

export const Fragment = graphql`
  fragment SanityKpiBlockFragment on SanityKpiBlock {
    title {
      ...LocaleString
    }
    metric
    metricV2
  }
`;

const statsFragment = gql`
  fragment KpiStatsFragment on StatsType {
    rows {
      currency @include(if: $requireCurrency)
      ngr @include(if: $ngr)
      ggr @include(if: $ggr)
      amountDeposits @include(if: $amountDeposits)
      amountWithdrawals @include(if: $amountWithdrawals)
      amountAdjustments @include(if: $amountAdjustments)
      amountBonusAdjustments @include(if: $amountBonusAdjustments)
      hold: hold_ @include(if: $hold)
    }
    error
  }
`;

const QUERY = gql`
  query KpiBox(
    $entityId: ID!
    $from: OffsetDateTime!
    $to: OffsetDateTime!
    $allTimeFrom: OffsetDateTime!
    $allTimeTo: OffsetDateTime!
    $includeAllTime: Boolean!
    $ngr: Boolean!
    $ggr: Boolean!
    $amountDeposits: Boolean!
    $amountWithdrawals: Boolean!
    $amountAdjustments: Boolean!
    $amountBonusAdjustments: Boolean!
    $hold: Boolean!
    $timeZone: String
    $requireCurrency: Boolean!
  ) {
    node(id: $entityId) {
      id
      __typename
      ... on EntityWithStatistics {
        stats(
          granularity: All
          from: $from
          to: $to
          timeZone: $timeZone
          exchangeRateBaseCurrency: "EUR"
        ) {
          ...KpiStatsFragment
        }
        allTimeStats: stats(
          granularity: All
          from: $allTimeFrom
          to: $allTimeTo
          timeZone: $timeZone
          exchangeRateBaseCurrency: "EUR"
        ) @include(if: $includeAllTime) {
          ...KpiStatsFragment
        }
      }
    }
  }
  ${statsFragment}
`;

const KpiBlock: FC<{
  block: SanityKpiBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();
  const { viewer } = useViewer();
  const { timeFrame, time } = useTime();

  const [previous, setPrevious] = useState<(number | undefined)[]>([]);

  const timeVariables = mapVariables(time);
  const allTime = mapVariables(predefinedTimeFrames['all-time'].getInterval());

  const isAllTimeSelected =
    timeFrame.name === predefinedTimeFrames['all-time'].name;

  const [{ data, fetching }] = useQuery<KpiBox, KpiBoxVariables>({
    query: QUERY,
    variables: {
      entityId: params.playerId || viewer?.id || '',
      ...timeVariables,
      allTimeFrom: allTime.from,
      allTimeTo: allTime.to,
      includeAllTime: !isAllTimeSelected,
      ggr: false,
      ngr: false,
      amountDeposits: false,
      amountWithdrawals: false,
      amountAdjustments: false,
      amountBonusAdjustments: false,
      hold: false,
      requireCurrency: !!params.playerId,
      ...block.metricV2
        ?.split(',')
        .reduce<Record<string, true>>(
          (acc, metricKey) => ({ ...acc, [metricKey]: true }),
          {},
        ),
    },
    // @ts-expect-error
    pollInterval: timeFrame.isRealTime ? 5000 : undefined,
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setPrevious([]);
  }, [time]);

  const baseNode =
    data?.node?.__typename === 'Viewer' || data?.node?.__typename === 'Player'
      ? data.node
      : null;

  const row = baseNode?.stats?.rows[0];
  const allTimeRow = baseNode?.allTimeStats?.rows[0];

  const metrics = block.metricV2?.split(',');

  const value = row && sum(metrics?.map((k) => row[k as keyof typeof row]));

  const allTimeValue =
    allTimeRow &&
    sum(metrics?.map((k) => allTimeRow[k as keyof typeof allTimeRow]));

  useEffect(() => {
    setPrevious((prev) => [last(prev), value]);
  }, [value]);

  const lastValue = previous[0] || 0;
  const currency = row?.currency || 'EUR';

  const valueChange = row && lastValue && value ? value - lastValue : undefined;

  return (
    <Card size="sm">
      <CardBody>
        <div className="p-3">
          <Value
            title={t(block.title)}
            fetching={fetching}
            error={baseNode?.stats?.error !== null}
          >
            <Tippy content="All time">
              <div>
                <Copy
                  value={formatMoney(
                    // show "value" when all time is selected since we don't fetch allTimeValue at that time
                    (isAllTimeSelected ? value : allTimeValue) ?? 0,
                    currency,
                  )}
                />
              </div>
            </Tippy>
            <Tippy content="Selected period">
              <div className="text-sm">
                {baseNode ? (
                  <Copy value={formatMoney(value ?? 0, currency)} />
                ) : (
                  <>-</>
                )}
              </div>
            </Tippy>
            <Tippy content="Value change in last seconds (when using live data)">
              <div className="text-sm">
                {valueChange ? (
                  valueChange >= 0 ? (
                    <span className="text-green-500">
                      +{formatMoney(valueChange, currency)}
                    </span>
                  ) : (
                    <span className="text-red-500">
                      {formatMoney(valueChange, currency)}
                    </span>
                  )
                ) : (
                  '-'
                )}
              </div>
            </Tippy>
          </Value>
        </div>
      </CardBody>
    </Card>
  );
};

export default withKeyByPlayerIdParam(KpiBlock);
