import React from 'react';
import { OptionTypeBase } from 'react-select';
import { OptionProps } from 'react-select/src/types';

import useSearchWorker from '@/hooks/useSearchWorker';
import { makeFluidThumbnail } from '@/utils/makeFluidThumbnail';
import { SearchAndSelectField } from '../form';
import { InlineSpinner } from '../InlineSpinner';

type GamesItemProps = {
  item: Game;
};

const OptionComponent = (props: OptionTypeBase) => {
  const thumbnail = props.value.thumbnail
    ? makeFluidThumbnail(props.value.thumbnail, props.value.thumbnailUpdatedAt)
    : null;
  return (
    <div className="flex flex-row w-full items-center pl-2 p-1 cursor-pointer hover:bg-gray-200">
      {thumbnail ? (
        <picture>
          <source sizes={thumbnail.sizes} srcSet={thumbnail.srcSet} />
          <img
            src={thumbnail.src}
            alt=""
            loading="lazy"
            className="w-6 h-6 rounded-sm mr-2"
          />
        </picture>
      ) : (
        <div className="w-6 h-6 rounded-sm mr-2 bg-gray-300" />
      )}
      <div className="flex flex-col flex-o w-full">
        <div className="flex-grow overflow-hidden overflow-ellipsis whitespace-nowrap">
          {props.children}
        </div>
      </div>
    </div>
  );
};

const OptionObject = ({ value }: OptionProps) => {
  return {
    itemId: `${value.studio}/${value.slug}`,
    name: value.name,
    mobileId: value.mobileId,
    desktopId: value.desktopId,
    providerName: value.provider,
  };
};

const loadOptions =
  (searchWorker: any) => (queryInput: string, callback: Function) => {
    const trimmedQuery = queryInput.trim();

    if (trimmedQuery.length > 0) {
      const games = searchWorker.search({ limit: 20, query: trimmedQuery });

      Promise.all([games]).then(([gamesRes]) => {
        const g =
          gamesRes.hits.map(({ item }: GamesItemProps) => ({
            value: item,
            label: item.name,
          })) || [];
        callback([...g]);
      });
    } else {
      callback([]);
    }
  };

type Props = {
  id: string;
  name: string;
  title: string;
};

export const GameSearch = ({ id, name, title }: Props) => {
  const { searchWorker, loading } = useSearchWorker();

  if (!searchWorker && loading) {
    return <InlineSpinner />;
  }

  return (
    <SearchAndSelectField
      id={id}
      name={name}
      title={title}
      loadOptions={loadOptions(searchWorker)}
      component={<OptionComponent />}
      config={{
        defaultAttrName: 'uniqueGameId',
        option: OptionObject,
      }}
    />
  );
};
