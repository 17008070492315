import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { PlayerComplianceHistorySearchOrder } from '@/globalTypes';
import { PlayerComplianceHistorySearchOrderParam } from '@/utils/query/params';

export const queryParams = {
  after: StringParam,
  before: StringParam,
  desc: withDefault(BooleanParam, true),
  eventType: StringParam,
  first: NumberParam,
  from: DateTimeParam,
  last: NumberParam,
  orderBy: withDefault(
    PlayerComplianceHistorySearchOrderParam,
    PlayerComplianceHistorySearchOrder.processedAt,
  ),
  to: DateTimeParam,
};
