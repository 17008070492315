import { useParams } from '@reach/router';
import classNames from 'classnames';
import { formatDistance } from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';
import React, { FC, useMemo } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { gql, useMutation, useQuery } from 'urql';

import {
  Card,
  CardBody,
  InlineSpinner,
  NakedForm,
  SelectField,
  SubmitButton,
  TextareaField,
  Tippy,
} from '@/components';
import {
  ChatIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@/components/icons';
import { useDrawer, useTranslate } from '@/contexts';
import { useDispatchNewNodes, useNewNodesOf } from '@/contexts/NewNodesContext';
import { PriorityEnum } from '@/globalTypes';
import { useOktaInfo } from '@/hooks';
import { Nullable } from '@/types';
import { cleanArray } from '@/utils';
import { useCan } from '@/utils/access';
import { assert } from '@/utils/error';
import formatDate from '@/utils/formatter/formatDate';
import {
  AddPlayerNote,
  AddPlayerNoteVariables,
} from './__generated__/AddPlayerNote';
import {
  PlayerNotesBlockQuery,
  PlayerNotesBlockQueryVariables,
  PlayerNotesBlockQuery_player_notes_edges_node_addedBy,
} from './__generated__/PlayerNotesBlockQuery';
import { StaticPlayerNotesBlockQuery } from './__generated__/StaticPlayerNotesBlockQuery';

export const playerNotes_note = gql`
  fragment PlayerNotes_note on Note {
    id
    playerId
    createdAt
    priority
    content
    addedBy {
      __typename
      ... on AgentInitiator {
        agent {
          email
          firstName
          lastName
        }
      }
      ... on PlayerInitiator {
        player {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

const QUERY = gql`
  query PlayerNotesBlockQuery($playerId: ID!) {
    player(playerId: $playerId) {
      id
      notes {
        edges {
          node {
            ...PlayerNotes_note
          }
        }
      }
    }
  }
  ${playerNotes_note}
`;

const ADD_NOTE_MUTATION = gql`
  mutation AddPlayerNote(
    $playerId: ID!
    $priority: PriorityEnum!
    $content: String!
  ) {
    addNote(playerId: $playerId, priority: $priority, content: $content) {
      ...PlayerNotes_note
    }
  }
  ${playerNotes_note}
`;

const PriorityIcon: FC<{ priority: PriorityEnum }> = ({ priority }) => {
  switch (priority) {
    case PriorityEnum.HIGH:
      return <ChevronUpIcon />;

    case PriorityEnum.MEDIUM:
      return <ChevronRightIcon />;

    case PriorityEnum.LOW:
      return <ChevronDownIcon />;

    default:
      return null;
  }
};

const getInitiator = (
  value?: PlayerNotesBlockQuery_player_notes_edges_node_addedBy,
) =>
  value?.__typename === 'PlayerInitiator' ? 'Player' : `${value?.agent?.email}`;

const staticQuery = graphql`
  query StaticPlayerNotesBlockQuery {
    sanityPlayerNotesBlock {
      title {
        ...LocaleString
      }
      content {
        ...LocaleString
      }
      priority {
        ...LocaleString
      }
      submit {
        ...LocaleString
      }
      noNotesPlaceholder {
        ...LocaleString
      }
    }
  }
`;

const getStorageKey = (playerId: string) => `PlayerNote:drawer:${playerId}`;

type FormValues = {
  content: '';
  priority: PriorityEnum;
};

const getEmptyDefaultValues = (
  canDefaultHighPriority: boolean,
): FormValues => ({
  content: '',
  priority: canDefaultHighPriority ? PriorityEnum.HIGH : PriorityEnum.LOW,
});

const getDefaultValues = (
  emptyDefaultValue: FormValues,
  storageKey: Nullable<string>,
): FormValues => {
  if (storageKey) {
    const stored = sessionStorage.getItem(storageKey);
    try {
      return (stored && JSON.parse(stored)) ?? emptyDefaultValue;
    } catch (error) {
      console.error(error);
    }
  }
  return emptyDefaultValue;
};

const PlayerNotesBlock = () => {
  const { t } = useTranslate();
  const params = useParams();
  const playerId: string = params.playerId;
  const newNotes = useNewNodesOf('Note');
  const dispatchNewNode = useDispatchNewNodes();

  const canDefaultHighPriority = useCan('DEFAULT_NOTE_HIGH_PRIORITY');
  const emptyDefaultValues = getEmptyDefaultValues(canDefaultHighPriority);

  assert(playerId, 'missing player id');

  const drawer = useDrawer();

  const storageKey = drawer && getStorageKey(playerId);

  const methods = useForm({
    defaultValues: getDefaultValues(emptyDefaultValues, storageKey),
  });

  const setFormToDefault = () => {
    Object.keys(emptyDefaultValues).forEach((k) => {
      const key = k as keyof FormValues;
      const value = emptyDefaultValues[key];
      methods.setValue(key, value);
    });
  };

  const stringifiedValues = JSON.stringify(methods.watch());
  useEffect(() => {
    if (stringifiedValues && storageKey) {
      sessionStorage.setItem(storageKey, stringifiedValues);
    }
  }, [storageKey, stringifiedValues]);

  const newPlayerNotes = useMemo(() => {
    return newNotes.filter((a) => a.playerId === playerId);
  }, [newNotes, playerId]);

  const { sanityPlayerNotesBlock: block } =
    useStaticQuery<StaticPlayerNotesBlockQuery>(staticQuery);

  const user = useOktaInfo();

  const [{ data, fetching }] = useQuery<
    PlayerNotesBlockQuery,
    PlayerNotesBlockQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId,
    },
    // @ts-expect-error
    pollInterval: 10000,
    requestPolicy: 'cache-and-network',
  });

  const [addNoteState, addNote] =
    useMutation<AddPlayerNote, AddPlayerNoteVariables>(ADD_NOTE_MUTATION);

  const now = new Date();

  const allNotes = useMemo(
    () =>
      _.uniqBy(
        [
          ...newPlayerNotes,
          ...cleanArray(data?.player.notes.edges).map(({ node }) => node),
        ],
        (entry) => entry.id,
      ),
    [data?.player.notes.edges, newPlayerNotes],
  );

  return (
    <Card title={t(block?.title)} size="md">
      <CardBody className="divide-y">
        <div className="p-3">
          <NakedForm
            className="space-y-3"
            methods={methods}
            onSubmit={(values) => {
              addNote({ playerId, ...values }).then(({ data }) => {
                if (data?.addNote.id) {
                  dispatchNewNode({
                    type: 'ADD_NODE',
                    nodeType: 'Note',
                    node: data.addNote,
                  });
                  if (storageKey) {
                    sessionStorage.removeItem(storageKey);
                  }
                  setFormToDefault();
                }
              });
            }}
          >
            <TextareaField
              name="content"
              id="player-notes-block__content"
              title={t(block?.content)}
              required
            />
            <SelectField
              name="priority"
              id="player-notes-block__priority"
              title={t(block?.priority)}
              options={[
                {
                  label: _.startCase(PriorityEnum.HIGH.toLowerCase()),
                  value: PriorityEnum.HIGH,
                },
                {
                  label: _.startCase(PriorityEnum.MEDIUM.toLowerCase()),
                  value: PriorityEnum.MEDIUM,
                },
                {
                  label: _.startCase(PriorityEnum.LOW.toLowerCase()),
                  value: PriorityEnum.LOW,
                },
              ]}
            />
            <SubmitButton
              value={t(block?.submit)}
              disabled={
                Object.values(methods.formState.errors).length > 0 ||
                addNoteState.fetching
              }
            />
          </NakedForm>
        </div>

        <ul className="space-y-3 p-3">
          {fetching ? (
            <div className="p-3 text-gray-500 text-center flex items-center flex-col space-y-1">
              <InlineSpinner />
            </div>
          ) : allNotes.length === 0 ? (
            <div className="p-3 text-gray-500 text-center flex items-center flex-col space-y-1">
              <div>
                <ChatIcon />
              </div>
              <div>{t(block?.noNotesPlaceholder)}</div>
            </div>
          ) : null}
          {allNotes.map((node, index, arr) => {
            const isLast = index === arr.length - 1;
            const date = new Date(node.createdAt);

            const messageFrom = getInitiator(node.addedBy);
            const messageIsFromMe = messageFrom === user.email;

            return (
              <li key={node.id} className="flex justify-start">
                <div className="flex flex-col justify-start flex-grow-0">
                  <Tippy content={_.startCase(node.priority.toLowerCase())}>
                    <div
                      className={classNames(
                        'w-5 h-5 rounded-full p-1 flex items-center justify-center',
                        {
                          'bg-red-300 text-red-800':
                            node.priority === PriorityEnum.HIGH,
                          'bg-yellow-200 text-yellow-700':
                            node.priority === PriorityEnum.MEDIUM,
                          'bg-green-200 text-green-900':
                            node.priority === PriorityEnum.LOW,
                        },
                      )}
                    >
                      <PriorityIcon priority={node.priority} />
                    </div>
                  </Tippy>
                  <div className="flex-grow-flex-grow flex-grow flex justify-center items-stretch">
                    {!isLast && (
                      <div className="line w-0.5 h-full rounded-md mt-1.5 mb-0 p-2 pt-0 pl-0 px-0 py-0 bg-gray-300"></div>
                    )}
                  </div>
                </div>
                <div className="flex-grow ml-3">
                  <div className="flex justify-start items-center space-x-3 flex-wrap">
                    <div className="font-medium text-sm text-gray-700 dark:text-gray-300">
                      {messageFrom}
                    </div>
                    <Tippy content={formatDate(date)}>
                      <div className="text-sm font-medium text-gray-500">
                        {formatDistance(date, now, {
                          addSuffix: true,
                        })}
                      </div>
                    </Tippy>
                  </div>
                  <div
                    className={classNames(
                      'pr-4 inline-block rounded-lg rounded-tl-none mt-1 px-3 py-1',
                      messageIsFromMe
                        ? 'bg-blue-600 dark:bg-blue-800 text-white'
                        : 'bg-gray-200 dark:bg-gray-700 dark:text-gray-200',
                      {
                        'animate-pulse': '__isNew' in node,
                        'mb-1': !isLast,
                      },
                    )}
                  >
                    {node.content.split('\n').map((a, i) => (
                      <div key={i}>{a}</div>
                    ))}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </CardBody>
    </Card>
  );
};

export default PlayerNotesBlock;
