import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ContextBuilder } from './ContextBuilder';

export type ThemeName = 'light' | 'dark';

type ThemeContextValue = {
  name: ThemeName;
  toggle: () => void;
};

const ThemeContext = new ContextBuilder<ThemeContextValue>('ThemeContext');

export const useTheme = ThemeContext.use;

const useGetInitialTheme = (): ThemeName => {
  const theme = useMemo<ThemeName>(() => {
    const stored = localStorage.getItem('theme');

    if (stored === 'dark') {
      return 'dark';
    }

    if (!stored && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    }

    return 'light';
  }, []);

  return theme;
};

const useThemeDomSync = (theme: ThemeName) => {
  useEffect(() => {
    if (theme === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [theme]);
};

export const ThemeProvider: FC = ({ children }) => {
  const initialTheme = useGetInitialTheme();
  const [theme, setTheme] = useState(initialTheme);

  useThemeDomSync(theme);

  useEffect(() => {
    const current = localStorage.getItem('theme');
    if (current !== theme) {
      localStorage.setItem('theme', theme);
    }
  }, [theme]);

  const toggle = useCallback(
    () => setTheme((current) => (current === 'dark' ? 'light' : 'dark')),
    [setTheme],
  );

  const ctx = useMemo<ThemeContextValue>(() => {
    return {
      name: theme,
      toggle,
    };
  }, [theme, toggle]);

  return (
    <ThemeContext.Context.Provider value={ctx}>
      {children}
    </ThemeContext.Context.Provider>
  );
};
