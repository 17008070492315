import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  RewardDetail,
  RewardDetailVariables,
} from './__generated__/RewardDetail';
import { queryParams } from './queryParams';

const REWARD_DETAIL_QUERY = gql`
  query RewardDetail(
    $id: String!
    $updatedAtFrom: OffsetDateTime
    $updatedAtTo: OffsetDateTime
    $rewardStatus: PlayerRewardStatus
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    viewer {
      id
      reward(id: $id) {
        name
        createdAt
        startsAt
        endsAt
        status
        createdBy {
          firstName
        }
        createdBy {
          firstName
          lastName
        }
        rewardType {
          name
          ... on FreeSpinReward {
            betLevel
            gameDescriptor {
              json
              url
            }
            amount
            value
            currency
          }
        }
        players(
          updatedAtFrom: $updatedAtFrom
          updatedAtTo: $updatedAtTo
          rewardStatus: $rewardStatus
          first: $first
          last: $last
          after: $after
          before: $before
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              playerId
              status
              updatedAt
              reason
              removedBy {
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

export default function useRewardDetail(id: string) {
  const [query, setQuery] = useQueryParams(queryParams);
  const [{ data, fetching }, refresh] = useQuery<
    RewardDetail,
    RewardDetailVariables
  >({
    query: REWARD_DETAIL_QUERY,
    variables: {
      first: 10,
      ...mapVariables(query),
      id,
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.reward?.players?.pageInfo),
    reward: data?.viewer.reward,
    rewardPlayers: data?.viewer.reward?.players?.edges?.map((e) => e?.node),
  };
}
