import { formatISO } from 'date-fns';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from 'urql';

import { TransactionType } from '@/globalTypes';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  adjustmentSummary_stats,
  getAdjustmentsSummaryRecord,
} from '@/utils/stats-with-currency';
import {
  PlayerAdjustmentsV2,
  PlayerAdjustmentsV2Variables,
} from './__generated__/PlayerAdjustmentsV2';
import { queryParams } from './queryParams';

const PLAYER_TRANSACTION_QUERY = gql`
  query PlayerAdjustmentsV2(
    $playerId: ID!
    $id: String
    $rawId: String
    $from: OffsetDateTime
    $to: OffsetDateTime
    $now: OffsetDateTime!
    $paymentStatus: [PaymentStatus!]
    $transactionTypes: [TransactionType!]
    $paymentMethod: String
    $minAmount: PositiveBigDecimal
    $maxAmount: PositiveBigDecimal
    $adjustmentType: AdjustmentType
    $adjustmentCategory: AdjustmentCategory
    $orderBy: TransactionSearchOrder
    $desc: Boolean
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    player(playerId: $playerId) {
      id
      allTimeStats: stats(
        granularity: All
        from: "2019-12-01T00:00:00+00:00"
        to: $now
      ) {
        rows {
          ...AdjustmentSummary_stats
        }
      }
      transactionsV2(
        id: $id
        rawId: $rawId
        from: $from
        to: $to
        paymentStatus: $paymentStatus
        transactionTypes: $transactionTypes
        paymentMethod: $paymentMethod
        minAmount: $minAmount
        maxAmount: $maxAmount
        adjustmentType: $adjustmentType
        adjustmentCategory: $adjustmentCategory
        orderBy: $orderBy
        desc: $desc
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        edges {
          node {
            processedAt
            amount
            currency
            transactionType
            comment

            initiator {
              ... on PlayerInitiator {
                player {
                  firstName
                  lastName
                  email
                }
              }
              ... on AgentInitiator {
                agent {
                  email
                  firstName
                  lastName
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
  ${adjustmentSummary_stats}
`;

export default function usePlayerTransactions(playerId: string) {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });

  const now = useMemo(() => new Date(), []);

  const [{ data, fetching }, refresh] = useQuery<
    PlayerAdjustmentsV2,
    PlayerAdjustmentsV2Variables
  >({
    query: PLAYER_TRANSACTION_QUERY,
    variables: {
      playerId,
      first: 20,
      ...mapVariables(query),
      now: formatISO(now),
      transactionTypes: [
        TransactionType.AdjustmentDeposit,
        TransactionType.AdjustmentWithdrawal,
      ],
    },
  });

  const totalAdjustmentSummary = getAdjustmentsSummaryRecord(
    data?.player.allTimeStats?.rows,
  );

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.player.transactionsV2.pageInfo),
    adjustments: data?.player.transactionsV2?.edges?.map((edge) => edge?.node),
    totalAdjustmentSummary,
  };
}
