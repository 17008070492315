import gql from 'graphql-tag';

export const limitFragment = gql`
  fragment LimitFragment on PeriodLimit {
    value
    available
    currentPeriodEndTime
    pendingPeriodLimit {
      configuredAt
      validFrom
      value
    }
    pendingPeriodLimitRemoval {
      validFrom
    }
  }
`;
