import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import {
  Area,
  Bar,
  ComposedChart,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';
import { useQuery } from 'urql';

import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import {
  PauseIcon,
  PlayIcon,
  RefreshIcon,
  ZoomInIcon,
} from '@/components/icons';
import { useTime, useTranslate, useViewer } from '@/contexts';
import { Nullable } from '@/types';
import { getProperty } from '@/utils';
import { SanityTimeChartBlockFragment } from './__generated__/SanityTimeChartBlockFragment';
import {
  TimeChartBox,
  TimeChartBoxVariables,
  TimeChartBox_node_Viewer_stats_rows,
} from './__generated__/TimeChartBox';
import { allNumericFields } from './allNumericFields';
import { formatDateWithGranularity } from './formatDateWithGranularity';
import { MappedResponse, mapStats } from './mapStats';

export const Fragment = graphql`
  fragment SanityTimeChartBlockFragment on SanityTimeChartBlock {
    title {
      ...LocaleString
    }
    metrics {
      title {
        ...LocaleString
      }
      function
      type
      metric
      color {
        hsl {
          h
          s
          l
          a
        }
      }
    }
  }
`;

const STATS_FRAGMENT = gql`
  fragment TimeStatsFragment on StatsType {
    rows {
      timePeriod
      amountAdjustments @include(if: $amountAdjustments)
      amountBonusAdjustments @include(if: $amountBonusAdjustments)
      amountCashbacks @include(if: $amountCashbacks)
      amountDeposits @include(if: $amountDeposits)
      amountFailedDeposits @include(if: $amountFailedDeposits)
      amountFailedWithdrawals @include(if: $amountFailedWithdrawals)
      amountFtd @include(if: $amountFtd)
      amountWin @include(if: $amountWin)
      amountWithdrawableWinnings @include(if: $amountWithdrawableWinnings)
      amountWithdrawals @include(if: $amountWithdrawals)
      ftd @include(if: $ftd)
      ftdOfNrc @include(if: $ftdOfNrc)
      ftp @include(if: $ftp)
      gameWin @include(if: $gameWin)
      ggr @include(if: $ggr)
      hold_ @include(if: $hold_)
      jpc @include(if: $jpc)
      margin @include(if: $margin)
      ngr @include(if: $ngr)
      nrc @include(if: $nrc)
      nrcByNsc @include(if: $nrcByNsc)
      nsc @include(if: $nsc)
      numAdjustments @include(if: $numAdjustments)
      numBlockAccountRequests @include(if: $numBlockAccountRequests)
      numCancelSelfExclusionRequests
        @include(if: $numCancelSelfExclusionRequests)
      numCashbacks @include(if: $numCashbacks)
      numCloseAccountRequests @include(if: $numCloseAccountRequests)
      numDeposits @include(if: $numDeposits)
      numFailedDeposits @include(if: $numFailedDeposits)
      numFailedWithdrawals @include(if: $numFailedWithdrawals)
      numReopenAccountRequests @include(if: $numReopenAccountRequests)
      numSelfExclusionRequests @include(if: $numSelfExclusionRequests)
      numTotalCloseAccountRequests @include(if: $numTotalCloseAccountRequests)
      numUniqueActivePlayers @include(if: $numUniqueActivePlayers)
      numUniqueDepositors @include(if: $numUniqueDepositors)
      numUniquePlayers @include(if: $numUniquePlayers)
      numUniqueSessions @include(if: $numUniqueSessions)
      numWithdrawals @include(if: $numWithdrawals)
      turnover @include(if: $turnover)
      wagers @include(if: $wagers)
      currency @include(if: $requireCurrency)
    }
  }
`;

const AREA_GRAPH_QUERY = gql`
  query TimeChartBox(
    $entityId: ID!
    $from: OffsetDateTime!
    $to: OffsetDateTime!
    $timeZone: String
    $granularity: GranularityEnum!
    $requireCurrency: Boolean!
    $amountAdjustments: Boolean!
    $amountBonusAdjustments: Boolean!
    $amountCashbacks: Boolean!
    $amountDeposits: Boolean!
    $amountFailedDeposits: Boolean!
    $amountFailedWithdrawals: Boolean!
    $amountFtd: Boolean!
    $amountWin: Boolean!
    $amountWithdrawableWinnings: Boolean!
    $amountWithdrawals: Boolean!
    $ftd: Boolean!
    $ftdOfNrc: Boolean!
    $ftp: Boolean!
    $gameWin: Boolean!
    $ggr: Boolean!
    $hold_: Boolean!
    $jpc: Boolean!
    $margin: Boolean!
    $ngr: Boolean!
    $nrc: Boolean!
    $nrcByNsc: Boolean!
    $nsc: Boolean!
    $numAdjustments: Boolean!
    $numBlockAccountRequests: Boolean!
    $numCancelSelfExclusionRequests: Boolean!
    $numCashbacks: Boolean!
    $numCloseAccountRequests: Boolean!
    $numDeposits: Boolean!
    $numFailedDeposits: Boolean!
    $numFailedWithdrawals: Boolean!
    $numReopenAccountRequests: Boolean!
    $numSelfExclusionRequests: Boolean!
    $numTotalCloseAccountRequests: Boolean!
    $numUniqueActivePlayers: Boolean!
    $numUniqueDepositors: Boolean!
    $numUniquePlayers: Boolean!
    $numUniqueSessions: Boolean!
    $numWithdrawals: Boolean!
    $turnover: Boolean!
    $wagers: Boolean!
  ) {
    node(id: $entityId) {
      id
      __typename
      ... on EntityWithStatistics {
        stats(
          granularity: $granularity
          from: $from
          to: $to
          timeZone: $timeZone
          exchangeRateBaseCurrency: "EUR"
        ) {
          ...TimeStatsFragment
        }
      }
    }
  }
  ${STATS_FRAGMENT}
`;

const getFillAndStrokeColor = (
  hsl: Nullable<Record<string, Nullable<number>>>,
) => {
  const { h, s, l, a } = { h: 0, s: 0, l: 70, a: 1, ...hsl };

  const result = {
    fill: `hsla(${h}, ${s * 100}%, ${l * 100}%, ${a})`,
    stroke: `hsla(${h}, ${s * 100}%, ${Math.max(l - 0.05, 0) * 100}%, ${a})`,
  };
  return result;
};

const graphTypes = {
  bar: Bar,
  line: Line,
  area: Area,
};

const emptyStatsArray: MappedResponse<TimeChartBox_node_Viewer_stats_rows>[] = [];

const isString = (a: unknown): a is string => typeof a === 'string';

const getAverage = (metric: string, data: { [key: string]: any }[]) =>
  data.reduce((acc, val) => acc + (val as { [key: string]: any })[metric], 0) /
  data.length;

const computeMovingAverage = (
  metric: string,
  f: string,
  data: { [key: string]: any }[],
  period: number,
) => {
  for (let x = 0; x + period - 1 < data.length; x += 1) {
    data[x + period - 1][metric + '_' + f] = getAverage(
      metric,
      data.slice(x, x + period),
    );
  }
};

const TimeChartBlock: FC<{
  block: SanityTimeChartBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();

  const { time, timeFrame, setCustomTime, graphQLVariables } = useTime();

  const { viewer } = useViewer();

  const [stats, setStats] = useState(emptyStatsArray);

  const [polling, togglePolling] = useToggle(true);
  const [zooming, toggleZooming] = useToggle(false);

  const [refArea, setRefArea] = useState<{
    refAreaLeft?: number;
    refAreaRight?: number;
  }>({});

  const allUsedMetrics = useMemo(() => {
    return block.metrics?.map((m) => m?.metric).filter(isString) || [];
  }, [block.metrics]);

  const usedFieldsVariables = allNumericFields.reduce<Record<string, boolean>>(
    (acc, field) => {
      acc[field] = allUsedMetrics.includes(field);
      return acc;
    },
    {},
  );

  const [{ data, stale }, refresh] = useQuery<
    TimeChartBox,
    TimeChartBoxVariables
  >({
    query: AREA_GRAPH_QUERY,
    // @ts-ignore we know that the usedFieldsVariable will contain all needed variables
    variables: {
      entityId: params.playerId || viewer?.id,
      ...graphQLVariables,
      requireCurrency: !!params.playerId,
      ...usedFieldsVariables,
    },
    pollInterval: timeFrame.isRealTime && polling ? 5000 : undefined,
    requestPolicy: 'cache-and-network',
  });

  const rows =
    data?.node &&
    (data.node.__typename === 'Viewer' || data.node.__typename === 'Player')
      ? data.node.stats?.rows || emptyStatsArray
      : emptyStatsArray;

  useEffect(() => {
    if (!stale) {
      const s = mapStats(time, rows, 'timePeriod', allUsedMetrics);

      (block.metrics || []).forEach((m) => {
        if (m?.function === 'mva7') {
          computeMovingAverage(m.metric || '', 'mva7', s, 7);
        } else if (m?.function === 'mva30') {
          computeMovingAverage(m.metric || '', 'mva30', s, 30);
        }
      });

      setStats(s);
    }
  }, [rows, block.metrics, setStats, allUsedMetrics, stale, time]);

  const nowLine = useMemo(() => {
    return timeFrame.isRealTime ? (
      <ReferenceLine x={new Date().getTime()} stroke="gray" />
    ) : null;
  }, [timeFrame]);

  if (!block.metrics || block.metrics.length === 0) {
    return null;
  }

  const zoom = () => {
    const { refAreaLeft, refAreaRight } = refArea;
    if (refAreaLeft && refAreaRight && refAreaLeft !== refAreaRight) {
      const [fromAmount, toAmount] = [refAreaLeft, refAreaRight].sort();
      setCustomTime({ from: new Date(fromAmount), to: new Date(toAmount) });
    }
    setRefArea({});
  };

  const { refAreaLeft, refAreaRight } = refArea;

  return (
    <Card
      size="md"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => toggleZooming()}
            active={zooming}
          >
            <ZoomInIcon />
          </CardOptionsButton>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!timeFrame.isRealTime}
            className="flex"
            onClick={() => togglePolling()}
          >
            {polling ? <PauseIcon /> : <PlayIcon />}
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="overflow-hidden rounded-b">
          <ResponsiveContainer height={150}>
            <ComposedChart
              data={stats}
              margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
              barSize={8}
              onMouseDown={(e: any) => {
                zooming &&
                  setRefArea((a) => ({ ...a, refAreaLeft: e.activeLabel }));
              }}
              onMouseMove={(e: any) =>
                refAreaLeft &&
                setRefArea((a) => ({ ...a, refAreaRight: e.activeLabel }))
              }
              onMouseUp={zoom}
            >
              {nowLine}
              <ReferenceLine y={0} stroke="gray" />
              <Tooltip
                labelFormatter={(a) => {
                  return formatDateWithGranularity(a, time.granularity);
                }}
              />
              <XAxis
                dataKey="__timeStamp"
                type="number"
                domain={['auto', 'auto']}
                hide
                scale="time"
              />
              {block.metrics.map((m) => {
                if (!m) {
                  return null;
                }

                const ChartComponent =
                  getProperty(graphTypes, m.type) || graphTypes.area;
                const dataKey = `${m.metric || ''}${
                  m.function ? '_' + m.function : ''
                }`;
                return (
                  // @ts-ignore we know that this component is a react component
                  <ChartComponent
                    dot={false}
                    key={dataKey}
                    isAnimationActive={false}
                    type="monotone"
                    dataKey={dataKey}
                    {...getFillAndStrokeColor({ ...m.color?.hsl })}
                    name={t(m.title)}
                  />
                );
              })}
              {refAreaLeft && refAreaRight ? (
                <ReferenceArea
                  x1={refAreaLeft}
                  x2={refAreaRight}
                  strokeOpacity={0.3}
                />
              ) : null}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </CardBody>
    </Card>
  );
};

export default memo(TimeChartBlock);
