import {
  BooleanParam,
  DateTimeParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { SearchRewardsOrderBy } from '@/globalTypes';
import { RewardStatusParam, RewardTypeEnumParam } from '@/utils/query/params';
import { enumParam } from '@/utils/query/params';

const SearchRewardsOrderByParam = enumParam(SearchRewardsOrderBy);

export const queryParams = {
  createdAtFrom: DateTimeParam,
  createdAtTo: DateTimeParam,
  createdBy: StringParam,
  rewardName: StringParam,
  rewardTypeEnum: RewardTypeEnumParam,
  playerIds: StringParam,
  startsAtFrom: DateTimeParam,
  startsAtTo: DateTimeParam,
  endsAtFrom: DateTimeParam,
  endsAtTo: DateTimeParam,
  rewardStatus: RewardStatusParam,
  desc: withDefault(BooleanParam, true),
  orderBy: withDefault(
    SearchRewardsOrderByParam,
    SearchRewardsOrderBy.createdAt,
  ),
  before: StringParam,
  after: StringParam,
};
