import gql from 'graphql-tag';
import { useQuery } from 'urql';

import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerKycHistory,
  PlayerKycHistoryVariables,
} from './__generated__/PlayerKycHistory';
import { queryParams } from './queryParams';

const PLAYER_KYC_HISTORY_QUERY = gql`
  query PlayerKycHistory(
    $playerId: ID!
    $kycProvider: KycProviderType
    $getOnlyLatestKycPerPlayer: Boolean
    $orderBy: PlayerKycHistorySearchOrder
    $desc: Boolean
    $from: OffsetDateTime
    $to: OffsetDateTime
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    player(playerId: $playerId) {
      id
      playerKycHistory(
        kycProvider: $kycProvider
        getOnlyLatestKycPerPlayer: $getOnlyLatestKycPerPlayer
        orderBy: $orderBy
        desc: $desc
        from: $from
        to: $to
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        edges {
          node {
            id
            uuid
            playerId
            rawPlayerId
            kycProvider
            initiator {
              ... on PlayerInitiator {
                player {
                  firstName
                  id
                  lastName
                  email
                }
              }
              ... on AgentInitiator {
                agent {
                  email
                  firstName
                  lastName
                }
              }
            }
            processedAt
            phoneNumber
            dateOfBirth
            firstName
            lastName
            street
            city
            zipCode
            ssn
            gender
            countryCode
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerKycHistory(playerId: string) {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });
  const [{ data, fetching }, refresh] = useQuery<
    PlayerKycHistory,
    PlayerKycHistoryVariables
  >({
    query: PLAYER_KYC_HISTORY_QUERY,
    variables: {
      playerId,
      first: 20,
      ...mapVariables(query),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.player.playerKycHistory.pageInfo),
    history: data?.player.playerKycHistory?.edges?.map((edge) => edge?.node),
  };
}
