import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerSearchV2,
  PlayerSearchV2Variables,
} from './__generated__/PlayerSearchV2';
import { queryParams } from './queryParams';

const PLAYER_SEARCH_QUERY = gql`
  query PlayerSearchV2(
    $id: String
    $email: EmailAddress
    $brand: BrandEnum
    $first: Int
    $after: String
    $before: String
    $firstName: String
    $lastName: String
    $birthDate: LocalDate
    $countryCode: String
    $phoneNumber: String
    $registrationStatus: RegistrationStatusFilter
    $ssn: String
    $registeredIp: String
    $registeredIpCountryCode: String
    $lastLoginFrom: OffsetDateTime
    $lastLoginTo: OffsetDateTime
    $lastLoginIp: String
    $lastLoginIpCountryCode: String
    $street: String
    $city: String
    $zipCode: String
    $minBalance: PositiveBigDecimal
    $maxBalance: PositiveBigDecimal
    $accountStatus: AccountStatusEnum
    $desc: Boolean
    $orderBy: PlayerSearchOrder
  ) {
    viewer {
      id
      playersSearchV2(
        id: $id
        email: $email
        first: $first
        after: $after
        brand: $brand
        before: $before
        firstName: $firstName
        lastName: $lastName
        birthDate: $birthDate
        countryCode: $countryCode
        phoneNumber: $phoneNumber
        registrationStatus: $registrationStatus
        ssn: $ssn
        registeredIp: $registeredIp
        registeredIpCountryCode: $registeredIpCountryCode
        lastLoginFrom: $lastLoginFrom
        lastLoginTo: $lastLoginTo
        lastLoginIp: $lastLoginIp
        lastLoginIpCountryCode: $lastLoginIpCountryCode
        street: $street
        city: $city
        zipCode: $zipCode
        minBalance: $minBalance
        maxBalance: $maxBalance
        accountStatus: $accountStatus
        orderBy: $orderBy
        desc: $desc
      ) {
        edges {
          node {
            playerId
            rawPlayerId
            firstName
            lastName
            birthDate
            countryCode
            phoneNumber
            ssn
            player {
              id
              selfExclusionDetails {
                id
                exclusionEndsAt
                willBeCancelledAt
                pending {
                  exclusionEndsAt
                  configuredAt
                  activeFrom
                }
              }
            }
            addressInfo {
              street
              city
              zipCode
            }
            emailInfo {
              email
            }
            registrationInfoV2 {
              registeredAt
              registrationIp
              registrationIpCountryCode
            }
            loginInfo {
              lastLoginAt
              lastLoginIp
              lastLoginIpCountryCode
            }
            balance
            accountStatus
            brand {
              id
              name
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerSearchResults() {
  const [query, setQuery] = useQueryParams(queryParams);
  const [{ data, fetching }, refresh] = useQuery<
    PlayerSearchV2,
    PlayerSearchV2Variables
  >({
    query: PLAYER_SEARCH_QUERY,
    variables: {
      first: 20,
      ...mapVariables(query),
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.playersSearchV2.pageInfo),
    playerSearchItems: data?.viewer.playersSearchV2?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
