import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useMemo, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  DateTimeField,
  ErrorMessage,
  Form,
  NumberField,
  SelectField,
  SelectOption,
  TextField,
  TextareaField,
  useModalContext,
} from '@/components';
import { SubmitButton } from '@/components/form/SubmitButton';
import { ClaimType, GameProvider } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { GameSearch } from '../../components/GameSearch';
import { PlayersSearch } from '../../components/PlayersSearch';
import {
  CreateFreeSpinsMutation,
  CreateFreeSpinsMutationVariables,
} from './__generated__/CreateFreeSpinsMutation';
import { RewardCreateFormStaticQuery } from './__generated__/RewardCreateFormStaticQuery';

const query = graphql`
  query RewardCreateFormStaticQuery {
    sanityRewardCreateForm {
      title {
        ...LocaleString
      }
    }
  }
`;

const createFreeSpinsMutation = gql`
  mutation CreateFreeSpinsMutation(
    $rewardName: String!
    $claimType: ClaimType!
    $startsAt: OffsetDateTime!
    $endsAt: OffsetDateTime
    $expiresIn: Duration
    $comment: String!
    $playerIds: [String!]!
    $spinsNumber: Int!
    $spinsValue: Long!
    $betLevel: Int!
    $currencyCode: ISOCurrencyCode!
    $gameProviderId: GameProvider!
    $gameMobileId: String
    $gameDesktopId: String
  ) {
    createFreeSpins(
      rewardName: $rewardName
      claimType: $claimType
      startsAt: $startsAt
      endsAt: $endsAt
      expiresIn: $expiresIn
      comment: $comment
      playerIds: $playerIds
      spinsNumber: $spinsNumber
      spinsValue: $spinsValue
      betLevel: $betLevel
      currencyCode: $currencyCode
      gameProviderId: $gameProviderId
      gameMobileId: $gameMobileId
      gameDesktopId: $gameDesktopId
    )
  }
`;

type Rewards = 'FREESPINS';
type RewardOption = SelectOption & { value: Rewards };
type FormValues = {
  rewardName: string;
  rewardType: RewardOption;
  claimType: ClaimType;
  startsAt: OffsetDateTime;
  endsAt?: OffsetDateTime | null;
  expiresIn?: Duration | null;
  comment: string;
  playerIds: string[];
  spinsNumber: number | null;
  spinsValue: number | null;
  betLevel: number;
  currencyCode: ISOCurrencyCode;
  gameDetails: {
    mobileId?: string | null;
    desktopId?: string | null;
  };
};

const useOptions = ({
  form,
}: {
  form: RewardCreateFormStaticQuery['sanityRewardCreateForm'];
}) => {
  return useMemo(() => {
    if (form) {
      const rewardTypesOptions: RewardOption[] = [
        {
          label: 'FreeSpins',
          value: 'FREESPINS',
        },
      ];

      const currencyOptions: SelectOption[] = [
        {
          label: 'Euros',
          value: 'EUR',
        },
        {
          label: 'Canadian Dollars',
          value: 'CAD',
        },
        {
          label: 'Dollars',
          value: 'USD',
        },
      ];

      const claimOptions: SelectOption[] = [
        {
          label: 'Claimable',
          value: 'Manual',
        },
        {
          label: 'Instant',
          value: 'Instant',
        },
      ];

      return {
        rewardTypesOptions,
        currencyOptions,
        claimOptions,
      };
    }

    return { rewardTypesOptions: [], currencyOptions: [], claimOptions: [] };
  }, [form]);
};

const RewardCreateForm: FC = () => {
  const staticData = useStaticQuery<RewardCreateFormStaticQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const { close } = useModalContext();

  const form = staticData.sanityRewardCreateForm;

  const { rewardTypesOptions, currencyOptions, claimOptions } = useOptions({
    form,
  });

  const [createFreeSpinsState, createFreeSpins] = useMutation<
    CreateFreeSpinsMutation,
    CreateFreeSpinsMutationVariables
  >(createFreeSpinsMutation);

  const defaultValues: FormValues = {
    rewardType: 'FREESPINS' as RewardOption,
    claimType: ClaimType.Manual,
    rewardName: '',
    startsAt: '',
    endsAt: '',
    expiresIn: undefined,
    comment: '',
    playerIds: [],
    spinsNumber: null,
    spinsValue: null,
    betLevel: 1,
    gameDetails: {
      mobileId: '',
      desktopId: '',
    },
    currencyCode: 'EUR' as ISOCurrencyCode,
  };

  const onSubmit = (values: FormValues) => {
    //TODO: This should be dynamic once the Games CMS is done.
    const gameProviderId = GameProvider.Relax;

    if (!values && gameProviderId) {
      return;
    }

    const variables: CreateFreeSpinsMutationVariables = {
      rewardName: values.rewardName,
      claimType: values.claimType,
      startsAt: values.startsAt,
      endsAt: values.endsAt,
      expiresIn: values.expiresIn,
      comment: values.comment,
      playerIds: values.playerIds,
      spinsNumber: Number(values.spinsNumber),
      spinsValue: Number(values.spinsValue),
      betLevel: values.betLevel,
      currencyCode: values.currencyCode,
      gameProviderId: gameProviderId,
      gameMobileId: values.gameDetails.mobileId,
      gameDesktopId: values.gameDetails.desktopId,
    };

    return createFreeSpins(variables).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title="Create reward"
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="flex p-6">
        <Form
          className="w-full"
          defaultValues={defaultValues}
          onSubmit={onSubmit}
        >
          <div className="flex sm:flex-row flex-col space-x-6 pb-6">
            <div className="flex-1 space-y-4">
              <SelectField
                name="rewardType"
                id="RewardCreateForm__rewardType"
                title="Reward Type"
                required
                options={rewardTypesOptions}
              />
              <div className="grid sm:grid-cols-2 gap-2">
                <TextField
                  name="spinsValue"
                  required
                  id="RewardCreateForm__spinsValue"
                  title="Value (cents)"
                />
                <SelectField
                  name="currencyCode"
                  required
                  id="RewardCreateForm__currency"
                  title="Currency"
                  options={currencyOptions}
                />
              </div>
              <div className="grid sm:grid-cols-2 gap-2">
                <NumberField
                  name="spinsNumber"
                  required
                  id="RewardCreateForm__spinsNumber"
                  title="Nr. of spins"
                  step="1"
                />
                <NumberField
                  name="betLevel"
                  required
                  id="RewardCreateForm__betLevel"
                  title="Bet level"
                  step="1"
                />
              </div>
              <GameSearch
                name="gameDetails"
                id="RewardCreateForm__gameDetails"
                title="Game"
              />
            </div>
            <div className="flex-1 space-y-4">
              <TextField
                name="rewardName"
                id="RewardCreateForm__rewardName"
                title="Name of Reward"
                required
              />
              <div className="grid sm:grid-cols-2 gap-2">
                <DateTimeField
                  title="Start Date"
                  name="startsAt"
                  id="analytics-block-settings__startDate"
                  required
                />
                <DateTimeField
                  title="End Date"
                  name="endsAt"
                  id="analytics-block-settings__endDate"
                  required
                />
              </div>
              <SelectField
                name="claimType"
                id="RewardCreateForm__claimType"
                title="Claimable/Instant"
                options={claimOptions}
                required
              />
              <PlayersSearch
                name="playerIds"
                id="RewardCreateForm__playerIds"
                title="Players"
              />
            </div>
            <div className="flex-1">
              <TextareaField
                name="comment"
                title="Notes"
                id="RewardCreateForm__comment"
              />
            </div>
          </div>

          <ErrorMessage message={errorMessage} />
          <div className="flex justify-end">
            <SubmitButton
              value="Create Reward"
              disabled={createFreeSpinsState.fetching}
            />
          </div>
        </Form>
      </div>
    </Card>
  );
};

export default RewardCreateForm;
