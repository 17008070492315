import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { Location, WindowLocation, globalHistory } from '@reach/router';
import { WrapRootElementBrowserArgs, navigate } from 'gatsby';
import React, { FC } from 'react';
import Modal from 'react-modal';
import { Client, Provider } from 'urql';
import { QueryParamProvider } from 'use-query-params';

import { makeAuthClient } from '@/bootstrap/okta';
import { makeClient } from '@/bootstrap/urql';
import { Feedbacker } from '@/components';
import { TimeProvider, TranslateProvider } from '@/contexts';
import { NewNodesProvider } from '@/contexts/NewNodesContext';
import { OrgEnum } from '@/globalTypes';
import { getOperatorFromPath, operatorsById } from '@/utils/operators';
import { useDevelopmentGraphQLEndpoint } from '@/utils/test-utils';

Modal.setAppElement('#___gatsby');

const clients: Partial<Record<OrgEnum, Client>> = {};

const ClientProvider: FC<{
  location: WindowLocation;
  authService: OktaAuth;
}> = ({ children, authService, location }) => {
  const currentOperator = getOperatorFromPath(location.pathname);
  const developmentEndpoint = useDevelopmentGraphQLEndpoint();

  const operator = currentOperator || operatorsById[OrgEnum.IN];

  const client =
    clients[operator.id] ||
    (clients[operator.id] = makeClient(
      developmentEndpoint ?? operator.graphQLEndpoint,
      authService,
    ));

  return <Provider value={client}>{children}</Provider>;
};

const getRelativeURL = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  const fullURL = link.href;
  if (typeof window !== 'undefined' && link.origin !== window.location.origin) {
    return fullURL;
  }
  return fullURL.substr(fullURL.indexOf(link.pathname));
};

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
  const authService = makeAuthClient();

  return (
    <Location>
      {({ location }) => (
        <QueryParamProvider location={location} reachHistory={globalHistory}>
          <Security
            oktaAuth={authService}
            restoreOriginalUri={(_, originalUrl) => {
              navigate(getRelativeURL(originalUrl), { replace: true });
            }}
          >
            <ClientProvider location={location} authService={authService}>
              <TranslateProvider>
                <TimeProvider root>
                  <NewNodesProvider>
                    <Feedbacker />
                    {element}
                  </NewNodesProvider>
                </TimeProvider>
              </TranslateProvider>
            </ClientProvider>
          </Security>
        </QueryParamProvider>
      )}
    </Location>
  );
};
