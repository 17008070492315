import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  Form,
  SelectField,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { AccountStatusEnum, CloseAccountCauseV2 } from '@/globalTypes';
import { assert } from '@/utils/error';
import {
  ClosePlayerAccountStatusMutation,
  ClosePlayerAccountStatusMutationVariables,
} from './__generated__/ClosePlayerAccountStatusMutation';
import {
  PlayerAccountStatusFormQuery,
  PlayerAccountStatusFormQuery_player,
} from './__generated__/PlayerAccountStatusFormQuery';
import { PlayerAccountStatusFormStaticQuery } from './__generated__/PlayerAccountStatusFormStaticQuery';
import {
  ReopenPlayerAccountStatusMutation,
  ReopenPlayerAccountStatusMutationVariables,
} from './__generated__/ReopenPlayerAccountStatusMutation';

const query = graphql`
  query PlayerAccountStatusFormStaticQuery {
    sanityPlayerAccountStatusForm {
      title {
        ...LocaleString
      }
      reasonLabel {
        ...LocaleString
      }
      closeAccountSubmit {
        ...LocaleString
      }
      reopenAccountSubmit {
        ...LocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerAccountStatusFormQuery($playerId: ID!) {
    player(playerId: $playerId) {
      id
      accountStatus {
        status
        cause
      }
      shouldReopenAt
    }
  }
`;

const closeMutation = gql`
  mutation ClosePlayerAccountStatusMutation(
    $playerId: ID!
    $closeCause: CloseAccountCauseV2!
  ) {
    closePlayerAccountV2(playerId: $playerId, closeCause: $closeCause) {
      id
      accountStatus {
        status
        cause
      }
      shouldReopenAt
    }
  }
`;

const reopenMutation = gql`
  mutation ReopenPlayerAccountStatusMutation($playerId: ID!) {
    reopenPlayerAccount(playerId: $playerId) {
      id
      accountStatus {
        status
        cause
      }
      shouldReopenAt
    }
  }
`;

const CloseAccountForm: FC<{
  player: PlayerAccountStatusFormQuery_player;
  form: NonNullable<
    PlayerAccountStatusFormStaticQuery['sanityPlayerAccountStatusForm']
  >;
}> = ({ player, form }) => {
  const { t } = useTranslate();
  const [closePlayerAccountState, closePlayerAccount] = useMutation<
    ClosePlayerAccountStatusMutation,
    ClosePlayerAccountStatusMutationVariables
  >(closeMutation);

  // TODO: remove the cast after we've unified the types in the API
  const defaultValues: { closeCause: CloseAccountCauseV2 } = {
    closeCause: player.accountStatus.cause as CloseAccountCauseV2,
  };

  const { close } = useModalContext();

  const onSubmit = (values: typeof defaultValues) => {
    if (values.closeCause) {
      closePlayerAccount({
        playerId: player.id,
        closeCause: values.closeCause,
      }).then((res) => {
        if (res.error?.message) {
        } else if (close) {
          close();
        }
      });
    }
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      className="grid grid-cols-2 sm:grid-cols-3 gap-6"
    >
      <SelectField
        title={t(form.reasonLabel)}
        name="closeCause"
        id="PlayerAccountStatusForm__closeCause"
        options={Object.keys(CloseAccountCauseV2).map((value) => ({
          value,
          label: value,
        }))}
      />
      <SubmitButton
        value={t(form.closeAccountSubmit)}
        disabled={closePlayerAccountState.fetching}
      />
    </Form>
  );
};

const ReopenAccountForm: FC<{
  player: PlayerAccountStatusFormQuery_player | undefined;
  form: NonNullable<
    PlayerAccountStatusFormStaticQuery['sanityPlayerAccountStatusForm']
  >;
}> = ({ player, form }) => {
  const { t } = useTranslate();
  const [reopenPlayerAccountState, reopenPlayerAccount] = useMutation<
    ReopenPlayerAccountStatusMutation,
    ReopenPlayerAccountStatusMutationVariables
  >(reopenMutation);

  const { close } = useModalContext();

  const onSubmit = () => {
    if (player) {
      reopenPlayerAccount({
        playerId: player.id,
      }).then((res) => {
        if (res.error?.message) {
        } else if (close) {
          close();
        }
      });
    }
  };

  if (!player) {
    return null;
  }

  return (
    <Form
      defaultValues={{}}
      onSubmit={onSubmit}
      className="grid grid-cols-2 sm:grid-cols-3 gap-6"
    >
      <SubmitButton
        value={t(form.reopenAccountSubmit)}
        disabled={reopenPlayerAccountState.fetching}
      />
    </Form>
  );
};

const PlayerAccountStatusForm: FC<{ playerId: string }> = ({ playerId }) => {
  const staticData = useStaticQuery<PlayerAccountStatusFormStaticQuery>(query);

  const form = staticData.sanityPlayerAccountStatusForm;

  assert(form, 'missing form data');

  const [{ data }] = useQuery<PlayerAccountStatusFormQuery>({
    query: playerQuery,
    variables: { playerId },
  });

  const { t } = useTranslate();

  const player = data?.player;

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        {player?.accountStatus.status === AccountStatusEnum.Open ||
        player?.shouldReopenAt ? (
          <CloseAccountForm player={player} form={form} />
        ) : (
          <ReopenAccountForm player={player} form={form} />
        )}
      </div>
    </Card>
  );
};

export default PlayerAccountStatusForm;
