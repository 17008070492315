import Papa from 'papaparse';
import React, { ChangeEvent, useEffect, useState } from 'react';

type Props = {
  list(items: unknown[]): void;
};

const UploadAndParseField = ({ list }: Props) => {
  const [rows, setRows] = useState<unknown[]>([]);

  useEffect(() => {
    list(rows);
  }, [list, rows]);

  const onUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        complete: ({ data }) => {
          setRows(data);
        },
      });
    }
  };

  return (
    <div>
      <label
        htmlFor="csvUpload"
        className="cursor-pointer border-gray-400 border-1 ml-1 align-top disabled:opacity-50 disabled:pointer-events-none dark:disabled:opacity-20 focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white inline-flex justify-center items-center rounded-md p-1 focus:outline-none text-gray-600 hover:text-gray-700 hover:bg-gray-300 active:bg-gray-400 hover:bg-opacity-30 dark:text-gray-200 dark:hover:text-gray-300 dark:hover:bg-gray-700 dark:active:bg-gray-800"
      >
        Upload CSV
      </label>
      <input
        id="csvUpload"
        className="hidden"
        type="file"
        name="csv-upload"
        accept=".csv"
        onChange={onUploadFile}
      />
    </div>
  );
};

export default UploadAndParseField;
