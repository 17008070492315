import { graphql, useStaticQuery } from 'gatsby';
import { keyBy } from 'lodash';
import { parse } from 'papaparse';
import React, { FormEvent, useState } from 'react';
import { CSVLink } from 'react-csv';
import { gql, useClient } from 'urql';

import { FileDrop, useFileDropFile } from '@/bits/file-drop';
import { useGetPlayerOverviewPageLink } from '@/bits/links/useLink';
import { Card, CardBody, ErrorMessage, SubmitButton } from '@/components';
import { DocumentDownloadIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { Nullable } from '@/types';
import { readFile } from '@/utils/file';
import {
  PlayersLinkGeneratorBlockPlayers,
  PlayersLinkGeneratorBlockPlayersVariables,
  PlayersLinkGeneratorBlockPlayers_viewer_playersSearchV2_edges_node,
} from './__generated__/PlayersLinkGeneratorBlockPlayers';
import { PlayersLinkGeneratorBlockStaticQuery } from './__generated__/PlayersLinkGeneratorBlockStaticQuery';

const BLOCK_STATIC_QUERY = graphql`
  query PlayersLinkGeneratorBlockStaticQuery {
    sanityPlayersLinkGeneratorBlock {
      title {
        ...LocaleString
      }
      submit {
        ...LocaleString
      }
    }
  }
`;

type UuidV4 = `${string}-${string}-${string}-${string}-${string}`;
type CsvValue = string | number | UuidV4;
type CsvRow = CsvValue[];

const uuidRe =
  /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

const isUuid = (value: unknown): value is UuidV4 => {
  return !!(typeof value === 'string' && value && uuidRe.test(value));
};

const PLAYER_IDS_QUERY = gql`
  query PlayersLinkGeneratorBlockPlayers($playerUUIDs: [String!]!) {
    viewer {
      id
      playersSearchV2(playerIds: $playerUUIDs, first: 1000) {
        edges {
          node {
            playerId
            uuid
          }
        }
      }
    }
  }
`;

const BulkAdjustmentsBlock = () => {
  const { t } = useTranslate();
  const block =
    useStaticQuery<PlayersLinkGeneratorBlockStaticQuery>(
      BLOCK_STATIC_QUERY,
    ).sanityPlayersLinkGeneratorBlock;
  const { dispatch, file } = useFileDropFile();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [csvRows, setCsvRows] = useState<CsvRow[] | null>(null);
  const client = useClient();
  const getPlayerOverviewPageLink = useGetPlayerOverviewPageLink();

  if (!block) {
    return null;
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!file) {
      return;
    }

    try {
      setIsGenerating(true);
      setCsvRows(null);

      const fileContent = await readFile(file);

      if (typeof fileContent !== 'string') {
        throw new Error('invalid file content');
      }

      const csvRows = parse<CsvRow>(fileContent).data;

      const playerUUIDs = csvRows.reduce<string[]>((acc, row) => {
        const [head] = row;
        if (isUuid(head)) {
          acc = [...acc, head];
        }
        return acc;
      }, []);

      const res = await client
        .query<
          PlayersLinkGeneratorBlockPlayers,
          PlayersLinkGeneratorBlockPlayersVariables
        >(PLAYER_IDS_QUERY, {
          playerUUIDs,
        })
        .toPromise();

      if (res.error) {
        setIsGenerating(false);
        return setErrorMessage(res.error.message);
      }

      const playerNodes = res.data?.viewer.playersSearchV2.edges?.reduce<
        PlayersLinkGeneratorBlockPlayers_viewer_playersSearchV2_edges_node[]
      >((acc, e) => {
        if (e) {
          acc = [...acc, e.node];
        }
        return acc;
      }, []);

      const playersByUUID = keyBy(playerNodes, (v) => v?.uuid);

      const newCsvRows = csvRows.map((row) => {
        const [uuid, ...tail] = row;
        const player = isUuid(uuid) && playersByUUID[uuid];
        const pageLinkPath =
          player && getPlayerOverviewPageLink(player.playerId);

        const baseUrl = `${window.location.protocol}//${window.location.hostname}`;
        const pageLink = pageLinkPath ? `${baseUrl}${pageLinkPath}` : '';

        return [uuid, pageLink, ...tail];
      });

      setCsvRows(newCsvRows);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-3 p-3">
            <div className="text-sm text-black dark:text-gray-200">
              Max 1000 rows in the CSV
            </div>
            <FileDrop
              dispatch={dispatch}
              file={file}
              accept="text/csv"
              setErrorMessage={setErrorMessage}
            />
            <SubmitButton
              value={t(block.submit)}
              disabled={!!errorMessage || !file || isGenerating}
            />
            <ErrorMessage message={errorMessage} />
          </div>
        </form>
        {csvRows && (
          <div className="p-3">
            <CSVLink
              filename={file?.name ?? 'file'}
              className="align-top disabled:opacity-50 disabled:pointer-events-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-800 focus-visible:ring-white inline-flex justify-center items-center rounded-md p-2 text-gray-600 hover:text-gray-700 hover:bg-gray-300 active:bg-gray-400 hover:bg-opacity-30 focus:outline-none dark:text-gray-200 dark:hover:bg-gray-700 dark:active:bg-gray-600 dark:hover:bg-opacity-30"
              data={csvRows}
            >
              <span className="mr-1">Download</span>
              <DocumentDownloadIcon />
            </CSVLink>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default BulkAdjustmentsBlock;
